import { EncounterDetails, IssuesResponseType } from "entities/issues";
import { InfoTypes, SinglePatientInfo } from "entities/patientInformation";
import { useEffect, useMemo, useState } from "react";
import { EncounterWidth, TileYearWidth } from "utils/constants";
import { ExtendedInfoType } from "../components/types";

export default function useMonthTimeline(
  patientInformations?: SinglePatientInfo[],
  issues?: IssuesResponseType
) {
  const [allMonths, setAllMonths] = useState<string[]>([]);
  const [maxEncounterWidth, setMaxEncounterWidth] = useState(0);

  const getMonthsBetween = (start: string, end: string): string[] => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const months = [];

    while (startDate <= endDate) {
      const month = startDate.getMonth() + 1; // getMonth returns 0-indexed month
      const year = startDate.getFullYear();
      const formattedMonth = month < 10 ? `0${month}` : month; // add leading zero if needed

      months.push(`${year}-${formattedMonth}`);

      // Move to the next month
      startDate.setMonth(startDate.getMonth() + 1);
    }

    return months;
  };

  const groupPatientData = useMemo(() => {
    const groupedData: Record<InfoTypes, ExtendedInfoType[]> = {
      allergy: [],
      dental: [],
      medical_problem: [],
      medication: [],
      surgery: [],
      tests: [],
      vitals: [],
      medical_device: [],
    };
    const date = new Date();
    const dateStringParts = date.toISOString().split("-");
    const year = +dateStringParts[0];
    const yearAndMonth = `${dateStringParts[0]}-${dateStringParts[1]}`;

    const newMonths = new Set(allMonths);

    if (patientInformations) {
      patientInformations.forEach((info) => {
        const beginingDate = +info?.begdate.split("-")[0];
        const endDate = info.enddate ? +info.enddate.split("-")[0] : year; //eg 2024

        let endYearMonth = info.enddate
          ? `${info.enddate.split("-")[0]}-${info.enddate.split("-")[1]}`
          : yearAndMonth; //eg 2024-04

        if (
          info.type === "surgery" ||
          info.type === "allergy" ||
          info.type === "medical_device" ||
          info.type === "dental"
        ) {
          endYearMonth = yearAndMonth;
        }

        const startYearMonth = info.begdate
          ? `${info.begdate.split("-")[0]}-${info.begdate.split("-")[1]}`
          : yearAndMonth; //eg 2024-04

        newMonths.add(startYearMonth);
        newMonths.add(endYearMonth);
        const months = getMonthsBetween(startYearMonth, endYearMonth);

        groupedData[info.type].push({
          ...info,
          enddate: info.enddate ?? date.toISOString(),
          tileWidth: (endDate - beginingDate) * TileYearWidth,
          end: endYearMonth,
          start: startYearMonth,
          contigiousDates: months,
        });
      });
    }

    setAllMonths(Array.from(newMonths).sort());

    return groupedData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientInformations]);

  const monthEncounters = useMemo(() => {
    const encounters: Record<string, EncounterDetails[]> = {};

    allMonths.forEach((month) => {
      encounters[month.toString()] = [];
    });

    if (issues) {
      issues.encounters.forEach((encounter) => {
        const encounterDateParts = encounter.date.split("-");
        const encounterMonth = `${encounterDateParts[0]}-${encounterDateParts[1]}`;
        if (encounterMonth !== "0000-00") {
          if (encounters[encounterMonth]) {
            encounters[encounterMonth] = [
              ...encounters[encounterMonth],
              ...encounter.encounters,
            ];
          } else {
            encounters[encounterMonth] = [...encounter.encounters];
          }
        }
      });
    }
    return encounters;
  }, [issues, allMonths]);

  useEffect(() => {
    const monthEncounterKeys = Object.keys(monthEncounters);
    let width = 0;
    for (const month of monthEncounterKeys) {
      if (monthEncounters[month as InfoTypes]) {
        width +=
          monthEncounters[month as InfoTypes].length * EncounterWidth + 400;
      } else {
        width += TileYearWidth;
      }
    }
    setMaxEncounterWidth(width);
  }, [monthEncounters]);

  return {
    months: Object.keys(monthEncounters).sort(),
    monthGroupPatientData: groupPatientData,
    monthEncounters,
    maxEncounterWidth,
  };
}
