import dayjs from "dayjs";
import { ConfigType } from "entities/config";
import { EncounterDetails } from "entities/issues";

type VitalsTabProps = {
  vitals?: ConfigType["vitals"];
  vitalsData: EncounterDetails["vitals"];
};

export const VitalsTab = ({ vitals, vitalsData }: VitalsTabProps) => {
  return (
    <div className={`flex w-full`}>
      <table className="min-w-full px-2  text-sm">
        <thead className={`bg-red-50`}>
          <tr className={`flex w-full py-2`}>
            {vitals?.map((data, i) => {
              return (
                <th
                  className={`w-24 px-6 py-4 items-bottom overflow-hidden justify-bottom font-sfuidisplayBlack`}
                  key={i}
                >
                  {data.name}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {vitalsData.map((data, i) => (
            <tr
              className={`flex w-full py-2 ${
                i % 2 == 0 ? "bg-gray-50" : "bg-gray-100"
              }`}
              key={i}
            >
              <td
                className={`w-24 px-2 items-center text-center overflow-hidden justify-center text-sm`}
              >
                {dayjs(data.date).format("YYYY-MM-DD")}
              </td>
              <td
                className={`w-24 px-2 items-center text-center overflow-hidden justify-center text-sm`}
              >
                {Number(data.bps).toFixed(2)} / {Number(data.bpd).toFixed(2)}{" "}
                mmHg
              </td>
              <td
                className={`w-24 px-2 items-center text-center overflow-hidden justify-center text-sm`}
              >
                {Number(data.height).toFixed(2)} In
              </td>
              <td
                className={`w-24 px-2 items-center text-center overflow-hidden justify-center text-sm`}
              >
                {data.temp_method} PM
              </td>
              <td
                className={`w-24 px-2 items-center text-center overflow-hidden justify-center text-sm`}
              >
                {data.respiration}
              </td>
              <td
                className={`w-24 px-2 items-center text-center overflow-hidden justify-center text-sm`}
              >
                {data.BMI_status}
              </td>
              <td
                className={`w-24 px-2 items-center text-center overflow-hidden justify-center text-sm`}
              >
                {data.oxygen_flow_rate}
              </td>
              <td
                className={`w-24 px-2 items-center text-center overflow-hidden justify-center text-sm`}
              >
                {Number(data.weight).toFixed(2)} lbs
              </td>
              <td
                className={`w-24 px-2 items-center text-center overflow-hidden justify-center text-sm`}
              >
                {Number(data.temperature).toFixed(2)}
              </td>
              <td
                className={`w-24 px-2 items-center text-center overflow-hidden justify-center text-sm`}
              >
                {Number(data.pulse).toFixed(2)} bpm
              </td>
              <td
                className={`w-24 px-2 items-center text-center overflow-hidden justify-center text-sm`}
              >
                {Number(data.BMI).toFixed(2)}
              </td>
              <td
                className={`w-24 px-2 items-center text-center overflow-hidden justify-center text-sm`}
              >
                {data.oxygen_saturation}
              </td>
              <td
                className={`w-24 px-2 items-center text-center overflow-hidden justify-center text-sm`}
              >
                {data.inhaled_oxygen_concentration}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
