import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

type CustomDatePickerProps = {
  date: Date | null;
  onChange: (date: Date | null) => void;
};

const CustomDatePicker = ({ date, onChange }: CustomDatePickerProps) => {
  return (
    <div className="w-fit py-2 bg-[#F7F7F7]">
      <DatePicker
        showPopperArrow
        showPreviousMonths
        closeOnScroll={true}
        selected={date}
        onChange={onChange}
        className="bg-[#F7F7F7] w-full h-full active:outline-none focus:outline-none focus:ring-0 active:ring-0 text-sm"
      />
    </div>
  );
};

export default CustomDatePicker;
