export const TileHeight = 20;
export const VitalsTileHeight = 30;
export const TitleHeight = 60;
export const TileYearWidth = 300;
export const defaultWidth = TileYearWidth * 3;
export const EncounterWidth = 100;
export const SidebarButtonHeight = 42;

export const vitalsToDisplay = [
  "inhaled_oxygen_concentration",
  "oxygen_saturation",
  "BMI",
  "pulse",
  "temperature",
  "weight",
  "oxygen_flow_rate",
  "BMI_status",
  "temp_method",
  "respiration",
  "height",
  "bpd",
  "date",
];

export const testsToDisplay = [
  "result_status",
  "result",
  "range",
  "result_text",
];

export const ENDPOINTS = {
  createData: "store_data.php",
  creatSoapNotes: "soap.php",
  saveLabProcedure: "procedure_order.php"
};

export const ConfigVitalsToUnitMapper: Record<string, string> = {
  "Blood Pressure": "(mmHg)",
  Height: "(in)",
  Date: "",
  "Temperature Method": "(F)",
  Respiration: "(pm)",
  "BMI Status": "",
  "Oxygen Flow Rate": "",
  Weight: "(lbs)",
  Temperature: "",
  Pulse: "(bpm)",
  BMI: "",
  "Oxygen Saturation": "",
  "Inhaled Oxygen Concentration": "",
};

export const ICD10ModalSelectOptions = [
  {
    value: "10",
    label: "10 Entries",
  },
  {
    value: "20",
    label: "20 Entries",
  },
  {
    value: "25",
    label: "25 Entries",
  },
  {
    value: "30",
    label: "30 Entries",
  },
  {
    value: "40",
    label: "40 Entries",
  },
  {
    value: "50",
    label: "50 Entries",
  },
];

export const dataEntryOptions = [
  {
    value: "medical_problem",
    label: "Medical Problem",
  },
  {
    value: "medication",
    label: "Medication",
  },
  {
    value: "allergy",
    label: "Allergy",
  },
  {
    value: "surgery",
    label: "Surgery",
  },
  {
    value: "dental",
    label: "Dental",
  },
  {
    value: "medical_device",
    label: "Medical Devices",
  },
  {
    value: "procedures",
    label: "Procedures",
  },
];

export const ProcedureOrderInputs = {
  orderStatus: [
    { label: "Pending", value: "pending" },
    { label: "Completed", value: "completed" },
    { label: "Routed", value: "routed" },
    { label: "Canceled", value: "canceled" },
  ],
  abn: [
    { label: "Not Required", value: "not_required" },
    { label: "Required", value: "required" },
    { label: "Signed", value: "not_requisignedred" },
  ],
  priority: [
    { label: "High", value: "high" },
    { label: "Normal", value: "normal" },
  ],
  orderPsc: [
    { label: "No", value: "0" },
    { label: "Yes", value: "1" },
  ],
  fasting: [
    { label: "No", value: "0" },
    { label: "Yes", value: "1" },
  ],
  historyOrder: [
    { label: "No", value: "0" },
    { label: "Yes", value: "1" },
  ],
  billing: [
    { label: "Self Payment", value: "P" },
    { label: "Third-Party", value: "T" },
    { label: "Bill Clinic", value: "C" },
  ],
};
