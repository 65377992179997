import { useMemo } from "react";

import dayjs from "dayjs";
import { ConfigType } from "entities/config";
import { EncounterDetails } from "entities/issues";
import { InfoTypes } from "entities/patientInformation";
import { getGroupWidth } from "utils/helpers";
import EncounterOverlay from "../overlay/EncounterOverlay";
import { EncounterOverlayHeightType } from "../overlay/type";
import TileGroup from "../tiles/TileGroup";
import { ExtendedInfoType, TabTypes } from "../types";

type MonthTimelineProps = {
  tabs?: TabTypes[];
  maxWidth: number;
  configData?: ConfigType;
  groupPatientData: Record<InfoTypes, ExtendedInfoType[]>;
  months: string[];
  searchTerm: string;
  encounters: Record<string, EncounterDetails[]>;
  showEncounters: string;
  onTileClick: (patientInfo?: ExtendedInfoType) => void;
  handleToggleSoapNotes: (encounter?: EncounterDetails) => void;
  encounterSectionHeight: EncounterOverlayHeightType;
  handleShowProcedueOrders: (encounterId: string) => void;
};

type HeaderTileProps = {
  groups: string[];
  width: number;
  encounters: Record<string, EncounterDetails[]>;
};

const HeaderTile = ({ groups, width, encounters }: HeaderTileProps) => {
  const formatDate = (date: string) => {
    const formattedDateArr = dayjs(date).format("MM-DD-YYYY").split("-");
    formattedDateArr.splice(1, 1);
    return formattedDateArr.join("-");
  };
  return (
    <div
      className="flex w-full"
      style={{
        height: `33px`,
        width: width,
      }}
    >
      {groups?.map((item, i) => (
        <div
          key={item + i}
          className="font-bold text-lg"
          style={{
            width: getGroupWidth(encounters, item),
          }}
        >
          {formatDate(item)}
        </div>
      ))}
    </div>
  );
};

const MonthTimeline = ({
  tabs,
  months,
  maxWidth,
  configData,
  groupPatientData,
  encounters,
  showEncounters,
  handleToggleSoapNotes,
  onTileClick,
  encounterSectionHeight,
  searchTerm,
  handleShowProcedueOrders,
}: MonthTimelineProps) => {
  const problemsRowCount = useMemo(() => {
    let count = 0;
    Object.keys(groupPatientData).forEach((key) => {
      count += groupPatientData[key as InfoTypes].length + 1;
    });
    return count + 1;
  }, [groupPatientData]);
  return (
    <>
      <HeaderTile groups={months} width={maxWidth} encounters={encounters} />

      {showEncounters === "true" && (
        <EncounterOverlay
          view="month"
          groups={months}
          width={maxWidth}
          encounters={encounters}
          encounterHeight={problemsRowCount}
          handleToggleSoapNotes={handleToggleSoapNotes}
          encounterSectionHeight={encounterSectionHeight}
          vitals={configData?.vitals}
          tests={configData?.tests}
          handleShowProcedueOrders={handleShowProcedueOrders}
        />
      )}

      {tabs?.map((tab, i) => (
        <TileGroup
          isCollapsed={tab.isCollapsed}
          tests={configData?.tests}
          vitals={configData?.vitals}
          groupData={groupPatientData[tab.type]}
          type={tab.type}
          maxWidth={maxWidth}
          key={i}
          groups={months}
          encounters={encounters}
          view="month"
          onTileClick={onTileClick}
          searchTerm={searchTerm}
        />
      ))}
    </>
  );
};

export default MonthTimeline;
