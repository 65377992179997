import { useMemo } from "react";

import { ConfigType } from "entities/config";
import { EncounterDetails } from "entities/issues";
import { InfoTypes } from "entities/patientInformation";
import EncounterOverlay from "../overlay/EncounterOverlay";
import { EncounterOverlayHeightType } from "../overlay/type";
import TileGroup from "../tiles/TileGroup";
import { ExtendedInfoType, TabTypes } from "../types";

type YearTimelineProps = {
  tabs?: TabTypes[];
  maxWidth: number;
  configData?: ConfigType;
  groupPatientData: Record<InfoTypes, ExtendedInfoType[]>;
  years: string[];
  encounters: Record<string, EncounterDetails[]>;
  showEncounters: string;
  onTileClick: (patientInfo?: ExtendedInfoType) => void;
  handleToggleSoapNotes: (encounter?: EncounterDetails) => void;
  encounterSectionHeight: EncounterOverlayHeightType;
  searchTerm: string;
  handleShowProcedueOrders: (encounterId: string) => void;
};

type HeaderTileProps = {
  groups: string[];
  width: number;
  encounters: Record<string, EncounterDetails[]>;
};

const HeaderTile = ({ groups, width, encounters }: HeaderTileProps) => {
  return (
    <div
      className="flex justify-end items-center"
      style={{
        height: `33px`,
        width: width,
      }}
    >
      {groups?.map((item, i) => (
        <div
          key={item + i}
          className="font-bold text-lg"
          style={{
            width: `${Object.keys(encounters).length * 500}px`,
          }}
        >
          {item}
        </div>
      ))}
    </div>
  );
};

const YearTimeline = ({
  tabs,
  years,
  maxWidth,
  configData,
  groupPatientData,
  encounters,
  showEncounters,
  onTileClick,
  handleToggleSoapNotes,
  encounterSectionHeight,
  searchTerm,
  handleShowProcedueOrders
}: YearTimelineProps) => {
  const problemsRowCount = useMemo(() => {
    let count = 0;
    Object.keys(groupPatientData).forEach((key) => {
      count += groupPatientData[key as InfoTypes].length + 1;
    });
    return count + 1;
  }, [groupPatientData]);
  return (
    <>
      <HeaderTile width={maxWidth} groups={years} encounters={encounters} />

      {showEncounters === "true" && (
        <EncounterOverlay
          view="year"
          groups={years}
          width={maxWidth}
          encounters={encounters}
          encounterHeight={problemsRowCount}
          handleToggleSoapNotes={handleToggleSoapNotes}
          encounterSectionHeight={encounterSectionHeight}
          vitals={configData?.vitals}
          tests={configData?.tests}
          handleShowProcedueOrders={handleShowProcedueOrders}
        />
      )}
      <div className="bg-[#F2F2F2]">
        {tabs?.map((tab, i) => (
          <TileGroup
            isCollapsed={tab.isCollapsed}
            tests={configData?.tests}
            vitals={configData?.vitals}
            groupData={groupPatientData[tab.type]}
            type={tab.type}
            maxWidth={maxWidth}
            key={i}
            groups={years}
            encounters={encounters}
            view="year"
            onTileClick={onTileClick}
            searchTerm={searchTerm}
          />
        ))}
      </div>
    </>
  );
};

export default YearTimeline;
