type SplashScreenPropType = {
  hasError: boolean;
};

const SplashScreen = (props: SplashScreenPropType) => {
  const { hasError } = props;
  return (
    <>
      <div className="fixed z-50 flex flex-col w-full h-full right-0 left-0 top-0 bottom-0 bg-white">
        <div className="grid h-screen place-items-center">
          <div className="flex flex-col">
            <div className="w-80 h-24 mx-auto items-center justify-center">
              <img
                src="/images/Medsov-logo.png"
                alt="app-image"
                width={400}
                height={70}
              />
            </div>
            <div
              className={`h-1 w-full transition-width transition-slowest ease ${
                hasError ? "bg-red-500" : " bg-gray-500"
              }`}
            ></div>
            {hasError ? (
              <div className="text-red-600 my-2">
                Connection lost ... Check your internet connectivity
              </div>
            ) : (
              <div className="font-sfuidisplayBlack text-4xl my-4 mx-auto text-primaryColor">
                Visual EHR
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SplashScreen;
