import { ConfigType } from "entities/config";
import { EncounterDetails } from "entities/issues";
import { Dispatch, SetStateAction, useState } from "react";
import EncountersTab from "./components/EncountersTab";
import LabsData from "./components/LabsData";
import { VitalsTab } from "./components/VitalsTab";

type EncounterPopoverContentProps = {
  encounter: EncounterDetails;
  tests?: ConfigType["tests"];
  vitals?: ConfigType["vitals"];
  handleCreateLabOrderClick: (id: string) => void;
};

type TabsProps = {
  setActivetab: Dispatch<SetStateAction<string>>;
  activeTab: string;
};

const Tabs = ({ setActivetab, activeTab }: TabsProps) => {
  return (
    <div className="sm:flex w-full items-center justify-between">
      <div className="flex w-full items-center justify-center">
        <div
          onClick={() => setActivetab("encounter")}
          className={`rounded-full focus:outline-none focus:ring-2 cursor-pointer  focus:bg-primaryRedDeepColor hover:text-white focus:ring-primaryRedDeepColor`}
        >
          <div
            className={`py-2 px-8 ${
              activeTab === "encounter"
                ? "bg-primaryRedDeepColor text-white font-sfuidisplayBlack"
                : "bg-primaryRedColor text-black"
            } rounded-full`}
          >
            <p>Encounter</p>
          </div>
        </div>
        <div
          onClick={() => setActivetab("vitals")}
          className={`rounded-full focus:outline-none focus:ring-2 cursor-pointer focus:bg-primaryRedDeepColor focus:ring-primaryRedDeepColor ml-4 sm:ml-8`}
        >
          <div
            className={`py-2 px-8 ${
              activeTab === "vitals"
                ? "bg-primaryRedDeepColor text-white font-sfuidisplayBlack"
                : "bg-primaryRedColor text-black"
            } hover:text-white hover:bg-primaryRedDeepColor rounded-full `}
          >
            <p>Vitals</p>
          </div>
        </div>
        <div
          onClick={() => setActivetab("lab")}
          className="rounded-full focus:outline-none focus:ring-2 cursor-pointer focus:bg-primaryRedDeepColor focus:ring-primaryRedDeepColor ml-4 sm:ml-8"
        >
          <div
            className={`py-2 px-8 ${
              activeTab === "lab"
                ? "bg-primaryRedDeepColor text-white font-sfuidisplayBlack"
                : "bg-primaryRedColor text-black"
            } hover:text-white hover:bg-primaryRedDeepColor rounded-full`}
          >
            <p>Lab Test</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const EncounterPopoverContent = (props: EncounterPopoverContentProps) => {
  const { encounter, tests, vitals, handleCreateLabOrderClick } = props;
  const [activeTab, setActivetab] = useState("encounter");

  return (
    <div className="w-full md:w-[45rem] m-auto bg-primaryRedBgColor min-h-[25rem] relative border-0 rounded-xl border-black overflow-hidden md:max-w-full ">
      <div className="md:flex">
        <div className="sm:px-6 w-full">
          <div className="py-4">
            <div className="py-1 text-sm">
              <Tabs activeTab={activeTab} setActivetab={setActivetab} />
            </div>

            <div className="mt-7 overflow-x-auto">
              {activeTab === "encounter" && (
                <EncountersTab encounter={encounter} />
              )}

              {activeTab === "vitals" && (
                <VitalsTab vitals={vitals} vitalsData={encounter.vitals} />
              )}

              {activeTab === "lab" && (
                <LabsData
                  labsData={encounter.labs}
                  labs={tests}
                  onCreateClick={() => handleCreateLabOrderClick(encounter.id)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EncounterPopoverContent;
