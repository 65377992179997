import React from "react";

import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f2e4e5",
    maxWidth: 900,
    color: "rgba(0, 0, 0, 0.87)",
    transitionDuration: 500,
  },
});

type CustomTooltipProps = {
  title: React.ReactNode;
  children: React.ReactNode;
  followCursor?: boolean;
  placement?: TooltipProps["placement"];
};

const CustomTooltip = (props: CustomTooltipProps) => {
  return (
    <CustomWidthTooltip
      title={props.title}
      arrow
      followCursor={props.followCursor}
      enterDelay={1000}
      enterNextDelay={1000}
      placement={props.placement || "top-start"}
    >
      <div>{props.children}</div>
    </CustomWidthTooltip>
  );
};

export default CustomTooltip;
