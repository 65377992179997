import { ConfigType } from "entities/config";
import { EncounterDetails } from "entities/issues";
import { EncounterWidth } from "utils/constants";
import { getGroupWidth } from "utils/helpers";
import { EncounterDays } from "./DayView";
import { EncounterMonth } from "./MonthView";
import { EncounterYear } from "./YearView";
import { EncounterOverlayHeightType } from "./type";

type EncounterOverlayProps = {
  groups: string[];
  width: number;
  tests?: ConfigType["tests"];
  vitals?: ConfigType["vitals"];
  view: "year" | "month" | "day";
  encounters: Record<string, EncounterDetails[]>;
  encounterHeight: number;
  handleToggleSoapNotes: (encounter?: EncounterDetails) => void;
  encounterSectionHeight: EncounterOverlayHeightType;
  handleShowProcedueOrders: (encounterId: string) => void;
};

const EncounterOverlay = ({
  groups,
  width,
  view,
  encounters,
  encounterHeight,
  handleToggleSoapNotes,
  encounterSectionHeight,
  tests,
  vitals,
  handleShowProcedueOrders
}: EncounterOverlayProps) => {
  return (
    <button className="h-full absolute top-0 flex" style={{ width: width }}>
      {view == "year" ? (
        <>
          {groups.map((year) => (
            <div key={year + 22} className="w-full">
              <EncounterYear
                encounter={encounters[year] ?? []}
                encounterHeight={encounterHeight}
                handleToggleSoapNotes={handleToggleSoapNotes}
                encounterSectionHeight={encounterSectionHeight}
                vitals={vitals}
                tests={tests}
                handleShowProcedueOrders={handleShowProcedueOrders}
              />
            </div>
          ))}
        </>
      ) : view == "month" ? (
        <>
          {groups.map((month) => (
            <div key={month + 22} className="w-full">
              <EncounterMonth
                encounter={encounters[month] ?? []}
                width={getGroupWidth(encounters, month)}
                encounterHeight={encounterHeight}
                handleToggleSoapNotes={handleToggleSoapNotes}
                encounterSectionHeight={encounterSectionHeight}
                vitals={vitals}
                tests={tests}
                handleShowProcedueOrders={handleShowProcedueOrders}
              />
            </div>
          ))}
        </>
      ) : (
        <>
          {groups.map((day, i) => (
            <div key={day + 22 + i} className="w-full">
              <EncounterDays
                encounter={encounters[day] ?? []}
                width={encounters[day].length * EncounterWidth + 300}
                encounterHeight={encounterHeight}
                handleToggleSoapNotes={handleToggleSoapNotes}
                encounterSectionHeight={encounterSectionHeight}
                vitals={vitals}
                tests={tests}
                handleShowProcedueOrders={handleShowProcedueOrders}
              />
            </div>
          ))}
        </>
      )}
    </button>
  );
};

export default EncounterOverlay;
