import { CreateDataInput, CreateLabProcedureInputType } from "entities/create";
import {
  AppDropdownsType,
  FavouriteDropdownType,
} from "entities/dropDownInformation";
import { LabsResponseType } from "entities/labs";
import { PatientInformationsType } from "entities/patientInformation";
import { useGetData } from "hooks/useGetData";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { EntryService } from "services/entries-service";
import { dataEntryOptions } from "utils/constants";
import { getSelectData } from "utils/helpers";
import AllergyForm from "./forms/AllergyForm";
import DentalForm from "./forms/DentalForm";
import DevicesForm from "./forms/DevicesForm";
import MedicationForm from "./forms/MedicationForm";
import ProblemsForm from "./forms/ProblemsForm";
import ProcedureOrders from "./forms/ProcedureOrders";
import SelectInput from "./forms/SelectInput";
import SurgeryForm from "./forms/SurgeryForm";
import {
  DataEntryChildrenHandler,
  Options,
  ProcedureDataEntryChildrenHandler,
} from "./forms/types";
import { ExtendedInfoType } from "./types";

type DataEntrySidebarPropType = {
  onOpenChange: (show: boolean) => void;
  patientInformations?: PatientInformationsType;
  selectedPatientInformation: ExtendedInfoType | null;
  username: string;
  pid: string;
  formMode?: "create" | "update";
  refreshData: () => void;
  labsArr?: LabsResponseType[];
  setSelectedPatientInformation: Dispatch<
    SetStateAction<ExtendedInfoType | null>
  >;
  handleToggleShowDataEntrySidebar: (show: boolean) => void;
  setFormMode: Dispatch<SetStateAction<"create" | "update">>;
  labOrdersEncounterId: string | null;
};

const DataEntrySidebar = (props: DataEntrySidebarPropType) => {
  const {
    onOpenChange,
    patientInformations,
    selectedPatientInformation,
    pid,
    username,
    refreshData,
    formMode = "create",
    labsArr,
    setSelectedPatientInformation,
    setFormMode,
    labOrdersEncounterId,
    handleToggleShowDataEntrySidebar,
  } = props;

  const [activeTab, setActiveTab] = useState("medical_problem");
  const [message, setMessage] = useState<{
    message: string;
    type: "error" | "success" | "info";
  }>({
    message: "",
    type: "success",
  });

  const problemFormRef = useRef<DataEntryChildrenHandler>();
  const medicationsFormRef = useRef<DataEntryChildrenHandler>();
  const surgeryFormRef = useRef<DataEntryChildrenHandler>();
  const devicesFormRef = useRef<DataEntryChildrenHandler>();
  const dentalFormRef = useRef<DataEntryChildrenHandler>();
  const allergyFormRef = useRef<DataEntryChildrenHandler>();
  const proceduresOrderRef = useRef<ProcedureDataEntryChildrenHandler>();

  const { data: dropdownsInfo } = useGetData<AppDropdownsType>("/dropdown.php");

  const { data: favouritesDropdown } =
    useGetData<FavouriteDropdownType>("/favorites.php");

  useEffect(() => {
    if (selectedPatientInformation) {
      setActiveTab(selectedPatientInformation.type.toLowerCase());
    }
  }, [selectedPatientInformation]);

  useEffect(() => {
    if (labOrdersEncounterId) {
      setActiveTab("procedures");
    }
  }, [labOrdersEncounterId]);

  const handleSaveData = useCallback(
    async (input: Partial<CreateDataInput>) => {
      setMessage({
        message: "Saving Data...",
        type: "info",
      });
      const functionItem =
        formMode == "create"
          ? EntryService.saveEntries
          : EntryService.updateEnteries;

      let item = input;
      if (formMode == "update") {
        item = { ...input, id: selectedPatientInformation?.id };
      }
      const { error, code, data } = await functionItem(item);

      if (error) {
        setMessage({
          message: error.message,
          type: "error",
        });
      } else {
        setMessage({
          message: data.message || "",
          type: "success",
        });
        refreshData();
        handleToggleShowDataEntrySidebar(false);
      }
    },
    [
      formMode,
      selectedPatientInformation,
      refreshData,
      handleToggleShowDataEntrySidebar,
    ]
  );
  const handleSaveProcedure = useCallback(
    async (input: Partial<CreateLabProcedureInputType>) => {
      if (!labOrdersEncounterId) {
        setMessage({
          message: "Encounter is required to save procedure orders...",
          type: "error",
        });
        return;
      }
      setMessage({
        message: "Saving Data...",
        type: "info",
      });
      const functionItem =
        formMode == "create"
          ? EntryService.saveLabProcedure
          : EntryService.updateLabProcedure;

      let item = { ...input, encounter_id: labOrdersEncounterId };
      if (formMode == "update") {
        item = {
          ...input,
          procedure_order_id: selectedPatientInformation?.id,
          encounter_id: labOrdersEncounterId,
        };
      }
      const { error, code, data } = await functionItem(item);

      if (error) {
        setMessage({
          message: error.message,
          type: "error",
        });
      } else {
        setMessage({
          message: data.message || "",
          type: "success",
        });
        refreshData();
        handleToggleShowDataEntrySidebar(false);
      }
    },
    [
      formMode,
      selectedPatientInformation,
      refreshData,
      handleToggleShowDataEntrySidebar,
      labOrdersEncounterId,
    ]
  );

  const validateFormInput = useCallback((input: Partial<CreateDataInput>) => {
    if (!input.color) {
      setMessage({
        message: "Color attribute is required",
        type: "error",
      });
      return false;
    }
    if (!input.icd_10) {
      setMessage({
        message: "ICD-10 attribute is required",
        type: "error",
      });
      return false;
    }
    if (!input.start_date) {
      setMessage({
        message: "Start date attribute is required",
        type: "error",
      });
      return false;
    }
    if (!input.name_display) {
      setMessage({
        message: "Display name attribute is required",
        type: "error",
      });
      return false;
    }
    return true;
  }, []);

  const handleSaveClick = async () => {
    let input;
    if (activeTab == "medical_problem" && problemFormRef.current) {
      input = problemFormRef.current.submitData();
    }
    if (activeTab == "medication" && medicationsFormRef.current) {
      input = medicationsFormRef.current.submitData();
    }
    if (activeTab == "allergy" && allergyFormRef.current) {
      input = allergyFormRef.current.submitData();
    }
    if (activeTab == "surgery" && surgeryFormRef.current) {
      input = surgeryFormRef.current.submitData();
    }
    if (activeTab == "dental" && dentalFormRef.current) {
      input = dentalFormRef.current.submitData();
    }
    if (activeTab == "medical_device" && devicesFormRef.current) {
      input = devicesFormRef.current.submitData();
    }
    if (activeTab == "procedures" && proceduresOrderRef.current) {
      const input = proceduresOrderRef.current.submitProcedure();
      await handleSaveProcedure(input);
      return;
    }
    if (input) {
      const isValid = validateFormInput(input);
      if (isValid) {
        await handleSaveData({
          ...input,
          pid,
          modifydate: new Date(),
          user: username,
        });
      }
    }
  };

  const patientInformationSelectOptions = useMemo(() => {
    const items = new Set();
    const data: Options[] = [];
    patientInformations?.forEach((item) => {
      if (!items.has(item.diagnosis)) {
        data.push({
          value: item.diagnosis || "",
          label: item.name,
        });
      }
      items.add(item.diagnosis);
    });
    return data;
  }, [patientInformations]);

  const occurancesSelectOptions = useMemo(
    () => getSelectData(dropdownsInfo?.occurrence),
    [dropdownsInfo?.occurrence]
  );
  const outcomesSelectOptions = useMemo(
    () => getSelectData(dropdownsInfo?.outcome),
    [dropdownsInfo?.outcome]
  );

  const verificationsSelectOptions = useMemo(
    () => getSelectData(dropdownsInfo?.verification),
    [dropdownsInfo?.verification]
  );

  const labsSelectOptions = useMemo(
    () =>
      labsArr?.map((item) => ({ label: item.username, value: item.id })) ?? [],
    [labsArr]
  );

  const referalSourcesSelectOptions = useMemo(() => {
    const items = new Set();
    const data: Options[] = [];
    dropdownsInfo?.referral_source.forEach((item) => {
      const fullName = `${item.fname} ${item.lname}`;
      if (!items.has(fullName)) {
        data.push({
          value: fullName,
          label: fullName,
        });
      }
      items.add(fullName);
    });
    return data;
  }, [dropdownsInfo?.referral_source]);

  const MedUsageSelectOptions = useMemo(
    () => getSelectData(dropdownsInfo?.medication_usage),
    [dropdownsInfo?.medication_usage]
  );

  const intentSelectOptions = useMemo(
    () => getSelectData(dropdownsInfo?.request_intent),
    [dropdownsInfo?.request_intent]
  );

  const severitySelectOptions = useMemo(
    () => getSelectData(dropdownsInfo?.severity),
    [dropdownsInfo?.severity]
  );

  const reactionSelectOptions = useMemo(
    () => getSelectData(dropdownsInfo?.reaction),
    [dropdownsInfo?.reaction]
  );

  return (
    <div className="h-screen flex gap-2 fixed right-0 top-12 ">
      <div className="h-screen border  w-[301px] border-[#EFF1F3] shadow-lg rounded-lg  px-4 pt-6 bg-[#EFF1F3]">
        <div>
          <div className="flex justify-between w-full items-center">
            <p className="text-xs font-medium leading-3">Select Entry type</p>

            <button type="button" onClick={() => onOpenChange(false)}>
              <img
                src="/icons/close-icon-3.svg"
                alt="close"
                width={12}
                height={12}
              />
            </button>
          </div>
          <div className="mt-4">
            <SelectInput
              options={dataEntryOptions}
              name=""
              onChange={(e) => {
                setActiveTab(e);
                setFormMode("create");
                setSelectedPatientInformation(null);
              }}
              background="pink"
              textColor="white"
              value={activeTab}
            />
          </div>
        </div>
        {activeTab == "procedures" ? (
          <ProcedureOrders
            users={referalSourcesSelectOptions}
            ref={proceduresOrderRef as any}
            labs={labsSelectOptions}
            labOrdersEncounterId={labOrdersEncounterId}
          />
        ) : (
          <div className="h-[78%] overflow-scroll overflow-y-scroll border border-dashed rounded-lg mt-4 p-4 bg-white">
            {activeTab == "medical_problem" && (
              <ProblemsForm
                occurances={occurancesSelectOptions}
                outcomes={outcomesSelectOptions}
                verifications={verificationsSelectOptions}
                problemFavourites={favouritesDropdown?.problem_favorites}
                patientInformations={patientInformationSelectOptions}
                selectedPatientInformation={selectedPatientInformation}
                ref={problemFormRef as any}
                referalSource={referalSourcesSelectOptions}
                patient={patientInformations}
              />
            )}
            {activeTab == "medication" && (
              <MedicationForm
                occurances={occurancesSelectOptions}
                outcomes={outcomesSelectOptions}
                verifications={verificationsSelectOptions}
                medUsage={MedUsageSelectOptions}
                intent={intentSelectOptions}
                medicationFavourites={favouritesDropdown?.medication_favorites}
                selectedPatientInformation={selectedPatientInformation}
                patientInformations={patientInformationSelectOptions}
                ref={medicationsFormRef as any}
                patient={patientInformations}
                referalSource={referalSourcesSelectOptions}
              />
            )}
            {activeTab == "allergy" && (
              <AllergyForm
                occurances={occurancesSelectOptions}
                outcomes={outcomesSelectOptions}
                verifications={verificationsSelectOptions}
                allergyFavourites={favouritesDropdown?.allergy_favorites}
                severities={severitySelectOptions}
                reactions={reactionSelectOptions}
                patientInformations={patientInformationSelectOptions}
                selectedPatientInformation={selectedPatientInformation}
                ref={allergyFormRef as any}
                patient={patientInformations}
                referalSource={referalSourcesSelectOptions}
              />
            )}
            {activeTab == "surgery" && (
              <SurgeryForm
                occurances={occurancesSelectOptions}
                outcomes={outcomesSelectOptions}
                verifications={verificationsSelectOptions}
                surgeryFavourites={favouritesDropdown?.suregery_favorites}
                selectedPatientInformation={selectedPatientInformation}
                patientInformations={patientInformationSelectOptions}
                ref={surgeryFormRef as any}
                patient={patientInformations}
                referalSource={referalSourcesSelectOptions}
              />
            )}
            {activeTab == "dental" && (
              <DentalForm
                occurances={occurancesSelectOptions}
                outcomes={outcomesSelectOptions}
                verifications={verificationsSelectOptions}
                selectedPatientInformation={selectedPatientInformation}
                patientInformations={patientInformationSelectOptions}
                dentalFavourites={favouritesDropdown?.problem_favorites}
                ref={dentalFormRef as any}
                patient={patientInformations}
                referalSource={referalSourcesSelectOptions}
              />
            )}
            {activeTab == "medical_device" && (
              <DevicesForm
                occurances={occurancesSelectOptions}
                outcomes={outcomesSelectOptions}
                verifications={verificationsSelectOptions}
                selectedPatientInformation={selectedPatientInformation}
                patientInformations={patientInformationSelectOptions}
                ref={devicesFormRef as any}
                patient={patientInformations}
                referalSource={referalSourcesSelectOptions}
              />
            )}
          </div>
        )}

        <div className="flex gap-4 w-full  mt-4">
          <button
            onClick={() => onOpenChange(false)}
            className="h-[29px] w-fit px-4 flex gap-2 items-center font-semibold text-sm rounded-lg bg-[#EF4444] text-white"
          >
            <img
              src="/icons/cancel-icon-white.svg"
              alt="cancel"
              height={12}
              width={12}
            />
            Cancel/Exit
          </button>

          <button
            onClick={handleSaveClick}
            className="h-[29px] w-[96px] px-4 flex gap-2 items-center justify-center font-semibold text-sm rounded-lg bg-[#29AAE1] text-white"
          >
            <img
              src="/icons/save-icon.svg"
              alt="cancel"
              height={12}
              width={12}
            />
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default DataEntrySidebar;
