import * as Dialog from "@radix-ui/react-dialog";
import { ProcedureResponseType } from "entities/labs";
import { useGetData } from "hooks/useGetData";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { CustomInput } from "../forms/InputWrapper";
import Modal from "./Modal";

type ProceduresModalProps = {
  isOpen: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
  handleSearchSelect: (item: ProcedureResponseType) => void;
  labId: string;
};

const ProceduresModal = (props: ProceduresModalProps) => {
  const { onOpenChange, isOpen, handleSearchSelect, labId } = props;

  const [searchTerm, setSearchTerm] = useState("");

  const { isLoading, data } = useGetData<ProcedureResponseType[]>(
    `/procedures.php?procedure=${searchTerm}`
  );

  const labProcedures = useMemo(() => {
    return data?.filter((item) => item.lab_id === labId) || [];
  }, [data, labId]);
  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Dialog.Title className="flex w-full items-center justify-center mb-4">
        <div className="bg-[#BD20A31F] rounded-lg h-[50px] text-sm font-bold flex px-4 items-center justify-center ">
          <h3>Procedure Section</h3>
        </div>
      </Dialog.Title>
      <div className="w-[400px]">
        <div>
          <CustomInput
            type="search"
            name="search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Procedure Search - Type to Search"
          />
        </div>

        <div className="w-[400px] grid grid-cols-12 px-2 border-b-[#000000] border-b py-2 mt-6">
          <p className="font-bold text-sm  col-span-6">Name</p>
          <p className="font-bold text-sm col-span-6 line-clamp-1">
            Procedure Type
          </p>
        </div>

        {isLoading ? (
          // <Spinner size="3" />
          <p>loading</p>
        ) : (
          <div className="max-h-[300px] w-full overflow-y-auto mt-2">
            {labProcedures.length == 0 ? (
              <div>No procedures availabe for selected lab</div>
            ) : (
              <>
                {labProcedures?.map((item, index) => (
                  <button
                    className="px-2 w-[400px] min-h-10 border-b text-[#039ACA] hover:bg-gray-300 grid grid-cols-12 items-center text-left"
                    key={item.procedure_code + index}
                    onClick={() => handleSearchSelect(item)}
                  >
                    <p className="col-span-6 mr-4 text-sm 1 py-2">
                      {item.name}
                    </p>
                    <p className="col-span-6 text-sm line-clamp-1 items-center">
                      {item.procedure_type}
                    </p>
                  </button>
                ))}
              </>
            )}
          </div>
        )}

        <div className="flex gap-4 w-full justify-end mt-6">
          <Dialog.Close>
            <button className="h-8 w-fit px-4 flex gap-2 items-center font-semibold text-sm rounded-lg bg-[#EF4444] text-white">
              <img
                src="/icons/cancel-icon-white.svg"
                alt="cancel"
                height={12}
                width={12}
              />
              Cancel/Exit
            </button>
          </Dialog.Close>
        </div>
      </div>
    </Modal>
  );
};

export default ProceduresModal;
