import { ExtendedInfoType } from "components/types";
import { EncounterDetails, IssuesResponseType } from "entities/issues";
import { InfoTypes, SinglePatientInfo } from "entities/patientInformation";
import { useEffect, useMemo, useState } from "react";
import { TileYearWidth } from "utils/constants";

/**
 * - Gets the maximum widh of the timeline window as well as all the years from  the first visit to the current year
 */

export default function useYearTimeline(
  patientInformations?: SinglePatientInfo[],
  issues?: IssuesResponseType
) {
  const [maxYearWidth, setMaxYearWidth] = useState(0);
  const [years, setYears] = useState<string[]>([]);

  useEffect(() => {
    let yearWidth = 0;
    const date = new Date();
    const year = date.getFullYear();

    patientInformations?.forEach((data) => {
      const encounterYear = +(data?.begdate.split("-")[0] || year);
      yearWidth = Math.max(yearWidth, (+year - +encounterYear) * TileYearWidth);
    });
    setMaxYearWidth(yearWidth);

    const yearArr = Array.from(
      { length: yearWidth / TileYearWidth + 1 },
      (_, index) => (year - index).toString()
    );
    setYears(yearArr.sort());
  }, [patientInformations]);

  const groupPatientData = useMemo(() => {
    const groupedData: Record<InfoTypes, ExtendedInfoType[]> = {
      allergy: [],
      dental: [],
      medical_problem: [],
      medication: [],
      surgery: [],
      tests: [],
      vitals: [],
      medical_device: [],
    };
    const date = new Date();
    const dateStringParts = date.toISOString().split("-");
    const year = +dateStringParts[0];

    if (patientInformations) {
      patientInformations.forEach((info) => {
        const beginingDate = +info?.begdate.split("-")[0];
        let endDate = info.enddate ? +info.enddate.split("-")[0] : year;
        if (
          info.type === "surgery" ||
          info.type === "allergy" ||
          info.type === "medical_device" ||
          info.type === "dental"
        ) {
          endDate = year;
        }

        const yearArr = Array.from(
          { length: endDate + 1 - beginingDate },
          (_, index) => (beginingDate + index).toString()
        );

        groupedData[info.type].push({
          ...info,
          contigiousDates: yearArr,
          enddate: info.enddate ?? date.toISOString(),
          tileWidth: (endDate - beginingDate) * TileYearWidth,
          end: endDate.toString(),
          start: beginingDate.toString(),
        });
      });
    }

    return groupedData;
  }, [patientInformations]);

  const yearEncounters = useMemo(() => {
    const encounters: Record<string, EncounterDetails[]> = {};

    years.forEach((year) => {
      encounters[year.toString()] = [];
    });
    if (issues) {
      issues.encounters.forEach((encounter) => {
        const encounterYear = encounter.date.split("-")[0];
        if (encounterYear !== "0000") {
          if (encounters[encounterYear]) {
            encounters[encounterYear] = [
              ...encounters[encounterYear],
              ...encounter.encounters,
            ];
          } else {
            encounters[encounterYear] = [...encounter.encounters];
          }
        }
      });
    }
    return encounters;
  }, [years, issues]);

  return {
    maxYearWidth,
    years,
    yearGroupPatientData: groupPatientData,
    yearEncounters,
  };
}
