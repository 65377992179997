import Typography from "components/Typography";
import { EncounterDetails } from "entities/issues";
import { InfoTypes } from "entities/patientInformation";
import {
  EncounterWidth,
  SidebarButtonHeight,
  TileHeight,
} from "utils/constants";
import {
  getGroupWidth,
  getTextColorBasedOnBg,
  transformText,
} from "utils/helpers";
import DataPopoverContent from "../popover/DataPopoverContent";
import CustomTooltip from "../popover/Tooltip";
import { ExtendedInfoType } from "../types";

type TileProps = {
  color?: string;
  data?: ExtendedInfoType;
  width: number;
  groups: string[];
  type?: InfoTypes;
  encounters: Record<string, EncounterDetails[]>;
  view: "year" | "month" | "day";
  onTileClick: (patientInfo?: ExtendedInfoType) => void;
  searchTerm: string;
};

type DividerTileProps = {
  width: number;
};

export const DividerTile = ({ width }: DividerTileProps) => {
  return (
    <div
      className="flex justify-end items-center w-full bg-black"
      style={{
        height: `${TileHeight}px`,
        width: width,
      }}
    ></div>
  );
};

export const DataTile = (props: TileProps) => {
  const {
    data,
    color,
    width,
    groups,
    type,
    encounters,
    view,
    onTileClick,
    searchTerm,
  } = props;

  return (
    <CustomTooltip
      followCursor
      title={<DataPopoverContent data={props.data} />}
    >
      <div
        className="flex justify-end items-center w-full my-2"
        style={{
          // backgroundColor: !data ? "#b2beb5" : "",
          height: `${!data ? SidebarButtonHeight : TileHeight}px`,
          width: width,
        }}
        role="button"
        onClick={() => {
          onTileClick(data);
        }}
      >
        {data ? (
          <>
            {groups.map((group, i) => (
              <div
                className="h-full my-2"
                key={group + data.name + i}
                style={{
                  width:
                    view === "year"
                      ? Object.keys(encounters).length * 500
                      : view === "month"
                      ? getGroupWidth(encounters, group)
                      : encounters[group].length * EncounterWidth + 300,
                  backgroundColor: data.contigiousDates.find((y) => group === y)
                    ? color || "#b2beb5"
                    : "white",
                  borderRadius:
                    data.contigiousDates[0] === group && // if start and end is the same group, round edges
                    groups.indexOf(data.end) === i
                      ? "8px"
                      : data.contigiousDates[0] === group // if group is the first date, round left edges
                      ? "8px 0 0 8px"
                      : groups.indexOf(data.end) === i // if group is the last date, round right edges
                      ? "0 8px 8px 0"
                      : "",
                }}
              >
                {groups.indexOf(data.end) === i && (
                  <div
                    className={`pr-2 `}
                    style={{
                      color: getTextColorBasedOnBg(color || "#b2beb5"),
                    }}
                  >
                    <Typography
                      align="right"
                      htmlTag="p"
                      type="pSmall"
                      clamp="clamp1"
                      searchText={searchTerm}
                    >
                      {data.name}
                    </Typography>
                  </div>
                )}
              </div>
            ))}
          </>
        ) : (
          <div className="px-2 w-full h-[22px] justify-end flex items-center text-white bg-[#040E1C]">
            <Typography weight="bold" htmlTag="p" type="pLarge" clamp="clamp1">
              {transformText(type)}
            </Typography>
          </div>
        )}
      </div>
    </CustomTooltip>
  );
};
