import React, { ChangeEvent } from "react";

type FormInputBoxProps = {
  title: string;
  children: React.ReactNode;
};

type InputProps = {
  type?: string;
  name: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  placeholder?: string;
};

export const CustomTextArea = ({
  onChange,
  value,
  name,
  placeholder,
}: InputProps) => {
  return (
    <textarea
      className="w-full h-[80px] border border-[#E7B9DF] focus:outline-none text-xs p-2 rounded-lg bg-[#F7F7F7]"
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
    />
  );
};

export const CustomInput = ({
  onChange,
  value,
  name,
  placeholder,
  type,
}: InputProps) => {
  return (
    <input
      type={type}
      className="w-full h-10 border border-[#E7B9DF] text-xs px-2 rounded-lg bg-[#F7F7F7] focus:outline-none"
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
    />
  );
};

export const FormInputBox = ({ title, children }: FormInputBoxProps) => {
  return (
    <div className="w-full">
      <p className="text-xs font-medium mb-2">{title}</p>
      {children}
    </div>
  );
};
