import * as Dialog from "@radix-ui/react-dialog";
import { Dispatch, SetStateAction } from "react";

type ModalProps = {
  open: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
  children: React.ReactNode;
};

function Modal(props: ModalProps) {
  const { open, onOpenChange, children } = props;

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/50 z-10" />
        <Dialog.Content className="fixed top-1/2 left-1/2 px-12 py-4 w-fit max-h-[95%]  -translate-x-1/2 -translate-y-1/2 rounded-xl z-10 bg-white  border">
          {children}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default Modal;
