import { APIError } from "entities/generic";
import { AxiosError } from "axios";

export function apiErrorHandler<T>(error: AxiosError) {
  const axiosError = error as AxiosError;
  return {
    data: null,
    error: axiosError?.response?.data as APIError<T>,
    code: axiosError?.response?.status || -1,
  };
}
