type HighlightedProps = {
  text: string;
  highlight: string;
};

const Highlighted = ({ text = "", highlight = "" }: HighlightedProps) => {
  const parts = text.split(new RegExp(`(${highlight})`, "gi"));
  return (
    <span>
      {" "}
      {parts.map((part, i) => (
        <span
          key={i}
          style={
            part.toLowerCase() === highlight.toLowerCase()
              ? { fontWeight: "bold", background: "yellow", color: "black" }
              : {}
          }
        >
          {part}
        </span>
      ))}{" "}
    </span>
  );
};

export default Highlighted;
