import { Options } from "components/forms/types";
import { TabTypes } from "components/types";
import { Dropdown } from "entities/dropDownInformation";
import { EncounterDetails } from "entities/issues";
import {
  EncounterWidth,
  SidebarButtonHeight,
  testsToDisplay,
  TitleHeight,
  vitalsToDisplay,
} from "./constants";

export const generateQueryString = (parameters: Record<string, any>) => {
  const queryStringArray = [];
  for (const key in parameters) {
    if (Array.isArray(parameters[key])) {
      for (const item of parameters[key] as string[]) {
        queryStringArray.push(`${key}=${encodeURIComponent(item)}`);
      }
    } else if (parameters[key] !== "") {
      queryStringArray.push(
        `${key}=${encodeURIComponent(parameters[key] as string | number)}`
      );
    }
  }

  return `${queryStringArray.join("&")}`;
};

export const getTabHeight = (divHeight: number, isCollapsed: boolean) => {
  const tempHeight = divHeight - SidebarButtonHeight;
  let height = TitleHeight;
  let marginBottom = 15;
  if (tempHeight > 0) {
    height = isCollapsed ? TitleHeight : divHeight;
  }

  if (tempHeight < 0) {
    marginBottom = 10;
  }

  return { height: height + marginBottom, marginBottom };
};

export const encounterSectionHeight = (tabs?: TabTypes[]) => {
  let patientIssueSection = 40;
  let vitalsSection = 0;
  let labSection = 0;

  tabs?.forEach((tab) => {
    const { height, marginBottom } = getTabHeight(
      tab.divHeight,
      tab.isCollapsed
    );

    if (tab.type === "vitals") {
      vitalsSection = height;
    } else if (tab.type === "tests") {
      labSection = height;
    } else {
      patientIssueSection += height;
    }
  });

  return {
    patientIssueSection: patientIssueSection,
    vitalsSection,
    labSection,
  };
};

export const transformText = (text?: string) => {
  return text?.split("_").join(" ").toUpperCase() || "";
};

export const transformToSnakeCase = (text?: string) => {
  return text?.toLowerCase().split(" ").join("_") || "";
};

export const getVitalsInformation = (encounter?: EncounterDetails) => {
  const infoArr: any[] = [];
  vitalsToDisplay.forEach((item) => {
    if (encounter?.vitals.length) {
      const encounterVitals = encounter.vitals[0];
      if (item === "date") {
        infoArr.push(encounterVitals.date.split(" ")[0]);
      } else if (item === "BMI_status") {
        infoArr.push(encounterVitals.BMI_status);
      } else if (item === "bpd") {
        if (encounterVitals.bpd && encounterVitals.bps) {
          infoArr.push(
            `${encounterVitals["bps"]}/${
              encounterVitals[item as keyof typeof encounterVitals]
            }`
          );
        } else {
          infoArr.push("-");
        }
      } else {
        infoArr.push(
          Number(encounterVitals[item as keyof typeof encounterVitals]).toFixed(
            2
          )
        );
      }
    } else {
      infoArr.push("-");
    }
  });
  return infoArr;
};

export const getLabInformation = (encounter?: EncounterDetails) => {
  const infoArr: any[] = [];

  testsToDisplay.forEach((test) => {
    if (encounter?.labs.length) {
      const encounterVitals = encounter.labs[0];
      infoArr.push(encounterVitals[test as keyof typeof encounterVitals]);
    } else {
      infoArr.push("-");
    }
  });

  return infoArr;
};

export const getGroupWidth = (
  encounters: Record<string, EncounterDetails[]>,
  month: string
) => {
  const monthEncounters = encounters[month];
  const width = monthEncounters.length * EncounterWidth + 400;
  return width;
};

function hexToRgb(hex: string): { r: number; g: number; b: number } | null {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (_, r, g, b) => r + r + g + g + b + b);

  const regex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
  const result = regex.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

function luminance(r: number, g: number, b: number): number {
  const a = [r, g, b].map((v) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

export const getTextColorBasedOnBg = (bgColor: string): string => {
  const rgb = hexToRgb(bgColor);
  if (!rgb) {
    throw new Error("Invalid background color");
  }

  const bgLuminance = luminance(rgb.r, rgb.g, rgb.b);
  // If luminance is higher, choose black text, else white text
  return bgLuminance > 0.4 ? "#000000" : "#FFFFFF";
};

export const getSelectData = (source?: Dropdown[]) => {
  const items = new Set();
  const data: Options[] = [];
  source?.forEach((item) => {
    if (!items.has(item.name)) {
      data.push({
        value: item.id || "",
        label: item.name,
      });
    }
    items.add(item.name);
  });
  return data;
};

export const formatString = (input: string): string => {
  return input
    .split(/[\s_]+/) // Split by whitespace or underscores
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) 
    .join(" "); 
};

export const getRandomHexColor = () => {
  const randomInt = Math.floor(Math.random() * 16777215);
  const hexColor = randomInt.toString(16).padStart(6, "0");
  return `#${hexColor}`;
};
