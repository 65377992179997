import { useGesture } from "@use-gesture/react";
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";

import { ConfigType } from "entities/config";
import { EncounterDetails, IssuesResponseType } from "entities/issues";
import { SinglePatientInfo } from "entities/patientInformation";
import useDayTimeline from "hooks/useDayTimeline";
import { useGetData } from "hooks/useGetData";
import useMonthTimeline from "hooks/useMonthTimeline";
import useYearTimeline from "hooks/useYearTimeline";
import { encounterSectionHeight } from "../utils/helpers";
import DayTimeline from "./timelines/DayTimeline";
import MonthTimeline from "./timelines/MonthTimeline";
import YearTimeline from "./timelines/YearTimeline";
import { ExtendedInfoType, TabTypes } from "./types";

type MainContentPropType = {
  configData?: ConfigType;
  tabs?: TabTypes[];
  patientInformations?: SinglePatientInfo[];
  showEncounters: string;
  onTileClick: (patientInfo?: ExtendedInfoType) => void;
  handleToggleSoapNotes: (encounter?: EncounterDetails) => void;
  view: string;
  setView: Dispatch<SetStateAction<"year" | "month" | "day">>;
  searchTerm: string;
  handleShowProcedueOrders: (encounterId: string) => void;
};
const MainContent = (props: MainContentPropType) => {
  const {
    configData,
    tabs,
    patientInformations,
    showEncounters,
    onTileClick,
    handleToggleSoapNotes,
    view,
    setView,
    searchTerm,
    handleShowProcedueOrders,
  } = props;

  const divRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [crop, setCrop] = useState({ x: 0, y: 0, scale: 1 });
  const { userId = "18" } = useParams();

  const { data: issues } = useGetData<IssuesResponseType>("/issues.php", {
    pid: userId,
  });

  useEffect(() => {
    const handler = (e: Event) => e.preventDefault();
    document.addEventListener("gesturestart", handler);
    document.addEventListener("gesturechange", handler);
    document.addEventListener("gestureend", handler);
    return () => {
      document.removeEventListener("gesturestart", handler);
      document.removeEventListener("gesturechange", handler);
      document.removeEventListener("gestureend", handler);
    };
  }, []);

  useGesture(
    {
      onPinch: ({ offset }) => {
        const newValue = offset[0];

        if (newValue < 1.5) setView("year");
        if (newValue > 1.5 && newValue < 2.5) setView("month");
        if (newValue > 2.5) setView("day");

        if (newValue < 1) {
          setCrop((prev) => ({ ...prev, scale: 1 }));
        } else if (newValue > 2.7) {
          setCrop((prev) => ({ ...prev, scale: 2.5 }));
        } else {
          setCrop((prev) => ({ ...prev, scale: newValue }));
        }
      },
    },

    {
      target: divRef,
      eventOptions: { passive: false },
      pinch: {
        from: () => [crop.scale, 0],
      },
    }
  );

  const { maxYearWidth, years, yearGroupPatientData, yearEncounters } =
    useYearTimeline(patientInformations, issues);

  const { months, monthGroupPatientData, monthEncounters, maxEncounterWidth } =
    useMonthTimeline(patientInformations, issues);

  const { days, dayGroupPatientData, daysEncounter, maxDaysEncounterWidth } =
    useDayTimeline(patientInformations, issues);

  const memoizedData = useMemo(() => encounterSectionHeight(tabs), [tabs]);

  return (
    <>
      <div
        className={`w-full h-full ml-4 relative overflow-x-scroll`}
        ref={containerRef}
      >
        <div
          ref={divRef}
          className="h-full mr-6"
          style={{
            width:
              view === "year"
                ? maxYearWidth * crop.scale
                : view === "month"
                ? maxEncounterWidth
                : maxDaysEncounterWidth,
            touchAction: "none",
          }}
        >
          {view === "year" && (
            <YearTimeline
              years={years}
              maxWidth={maxYearWidth * crop.scale}
              groupPatientData={yearGroupPatientData}
              configData={configData}
              tabs={tabs}
              encounters={yearEncounters}
              showEncounters={showEncounters}
              onTileClick={onTileClick}
              handleToggleSoapNotes={handleToggleSoapNotes}
              encounterSectionHeight={memoizedData}
              searchTerm={searchTerm}
              handleShowProcedueOrders={handleShowProcedueOrders}
            />
          )}

          {view === "month" && (
            <MonthTimeline
              months={months}
              maxWidth={maxEncounterWidth}
              groupPatientData={monthGroupPatientData}
              configData={configData}
              tabs={tabs}
              encounters={monthEncounters}
              showEncounters={showEncounters}
              onTileClick={onTileClick}
              handleToggleSoapNotes={handleToggleSoapNotes}
              encounterSectionHeight={memoizedData}
              searchTerm={searchTerm}
              handleShowProcedueOrders={handleShowProcedueOrders}
            />
          )}

          {view === "day" && (
            <DayTimeline
              days={days}
              maxWidth={maxDaysEncounterWidth}
              groupPatientData={dayGroupPatientData}
              configData={configData}
              tabs={tabs}
              encounters={daysEncounter}
              showEncounters={showEncounters}
              onTileClick={onTileClick}
              handleToggleSoapNotes={handleToggleSoapNotes}
              encounterSectionHeight={memoizedData}
              searchTerm={searchTerm}
              handleShowProcedueOrders={handleShowProcedueOrders}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default MainContent;
