import { useCallback, useEffect, useMemo, useState } from "react";

import { ConfigType } from "./entities/config";

import DataEntrySidebar from "components/DataEntrySidebar";
import { LabsResponseType } from "entities/labs";
import { useSearchParams } from "react-router-dom";
import Header from "./components/Header";
import MainContent from "./components/MainContent";
import SideBar from "./components/SideBar";
import SoapNotes from "./components/SoapNotes";
import SplashScreen from "./components/SplashScreen";
import { ExtendedInfoType } from "./components/types";
import { SoapnotesInputType } from "./entities/create";
import { EncounterDetails, IssuesResponseType } from "./entities/issues";
import {
  InfoTypes,
  PatientInformationsType,
} from "./entities/patientInformation";
import { UserType } from "./entities/user";
import { useGetData } from "./hooks/useGetData";
import { EntryService } from "./services/entries-service";
import { TileHeight, VitalsTileHeight } from "./utils/constants";

function App() {
  const [searchParams] = useSearchParams();

  const [searchTerm, setSearchTerm] = useState("");

  const showEncounters = searchParams.get("showEncounters") || "false";

  const [showDataEntrySidebar, setShowDataEntrySideBar] = useState(false);
  const [labOrdersEncounterId, setLabOrdersEncounterId] = useState<
    string | null
  >(null);
  const [formMode, setFormMode] = useState<"create" | "update">("create");

  const [selectedPatientInformation, setSelectedPatientInformation] =
    useState<ExtendedInfoType | null>(null);
  const [view, setView] = useState<"year" | "month" | "day">("year");

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [showSoapNotes, setShowSoapNotes] = useState(false);
  const [mainContentWidth, setMainContentWidth] = useState("");
  const [collapsedNav, setCollapsedNav] = useState<string[]>([]);

  const [message, setMessage] = useState<{
    message: string | null;
    isError: boolean;
  }>({ message: null, isError: false });

  const [activeEncounter, setActiveEncounter] = useState<
    EncounterDetails | undefined
  >(undefined);
  const userId = useMemo(() => "18", []);

  const {
    data: configData,
    isLoading: isConfigLoading,
    error: isConfigError,
  } = useGetData<ConfigType>("/fetch.php");

  const {
    data: patientInformations,
    isLoading,
    refetch,
  } = useGetData<PatientInformationsType>("/active_codes.php", {
    pid: userId,
  });
  const { data: userInformation, isLoading: isUserLoading } =
    useGetData<UserType>("user.php", {
      userId,
    });

  const { refetch: refetchData } = useGetData<IssuesResponseType>(
    "/issues.php",
    {
      pid: userId,
    }
  );

  const { data: LabsArr } = useGetData<LabsResponseType[]>("/labs.php");

  const handleTileClick = useCallback((patientInfo?: ExtendedInfoType) => {
    setSelectedPatientInformation(patientInfo ?? null);
    setFormMode("update");
    setShowDataEntrySideBar(true);
  }, []);

  const tabs = useMemo(() => {
    const tabsToHeightMapper: Record<InfoTypes, number> = {
      allergy: 0,
      dental: 0,
      medical_problem: 0,
      medication: 0,
      surgery: 0,
      tests: 0,
      vitals: 0,
      medical_device: 0,
    };
    if (patientInformations) {
      patientInformations.forEach((info) => {
        tabsToHeightMapper[info.type]++;
      });
    }

    const data = configData?.sidebarlist.map((data) => {
      const collapsedNavSet = new Set(collapsedNav);
      const tabData =
        tabsToHeightMapper[data.type as keyof typeof tabsToHeightMapper];
      let divHeight = 0;
      if (tabData == 0 && data.type == "vitals") {
        divHeight = configData.vitals.length * VitalsTileHeight;
      } else if (tabData == 0 && data.type == "tests") {
        divHeight = configData.tests.length * VitalsTileHeight;
      } else {
        divHeight = tabData * TileHeight + tabData * 16;
      }
      return {
        ...data,
        divHeight,
        isCollapsed: collapsedNavSet.has(data.type) ? true : false,
      };
    });
    return data;
  }, [patientInformations, configData, collapsedNav]);

  const toggleProblemGroups = useCallback(
    (type: InfoTypes) => {
      const collapsedNavSet = new Set(collapsedNav);
      if (collapsedNavSet.has(type)) {
        collapsedNavSet.delete(type);
      } else {
        collapsedNavSet.add(type);
      }
      setCollapsedNav(Array.from(collapsedNavSet));
    },
    [collapsedNav]
  );

  const handleToggleShowSideBar = useCallback(() => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
    setShowSoapNotes(false);
    setShowDataEntrySideBar(false);
  }, [isSidebarCollapsed]);

  const handleToggleSoapNotes = useCallback((encounter?: EncounterDetails) => {
    setShowSoapNotes(true);
    setShowDataEntrySideBar(false);
    setIsSidebarCollapsed(true);
    setActiveEncounter(encounter);
  }, []);

  const handleToggleShowDataEntrySidebar = useCallback((show: boolean) => {
    setShowDataEntrySideBar(show);
    setShowSoapNotes(false);
    setIsSidebarCollapsed(true);
    if (!show) {
      setLabOrdersEncounterId(null);
    }
  }, []);

  const handleShowProcedueOrders = useCallback(
    (encounterId: string) => {
      setLabOrdersEncounterId(encounterId);
      handleToggleShowDataEntrySidebar(true);
    },
    [handleToggleShowDataEntrySidebar]
  );

  const refreshInformations = useCallback(() => {
    refetch();
    refetchData();
  }, [refetch, refetchData]);

  const handleSubmitSoapnotes = useCallback(
    async (input: Partial<SoapnotesInputType>) => {
      if (userInformation) {
        const { error } = await EntryService.saveSoapnotes({
          ...input,
          user: userInformation[0].username,
          pid: activeEncounter ? +activeEncounter.pid : null,
          eid: activeEncounter ? +activeEncounter.encounter : null,
          authorized: 1,
          activity: 1,
        });
        if (error) {
          setMessage({ message: "Failed to save record", isError: true });
        } else {
          setMessage({ message: "Record saved", isError: false });
          setTimeout(() => {
            handleToggleShowSideBar();
            setMessage({ message: null, isError: false });
          }, 3000);
          refreshInformations();
        }
      }
    },
    [
      activeEncounter,
      userInformation,
      handleToggleShowSideBar,
      refreshInformations,
    ]
  );

  useEffect(() => {
    if (showSoapNotes && isSidebarCollapsed) {
      setMainContentWidth("w-[calc(100vw-682px)]");
    } else if (showDataEntrySidebar) {
      setMainContentWidth("w-[calc(100vw-382px)]");
    } else if (isSidebarCollapsed) {
      setMainContentWidth("w-[calc(100vw-80px)]");
    } else {
      setMainContentWidth("w-[calc(100vw-168px)]");
    }
  }, [isSidebarCollapsed, showSoapNotes, showDataEntrySidebar]);

  if (isConfigLoading || isConfigError || isLoading || !userInformation) {
    return <SplashScreen hasError={!!isConfigError} />;
  }

  return (
    <>
      <main className="flex min-h-screen min-w-screen">
        <SideBar
          collapsed={isSidebarCollapsed}
          toggleSidebar={handleToggleShowSideBar}
          tabs={tabs}
          toggleProblemGroups={toggleProblemGroups}
        />
        <div className={`w-full h-full`}>
          <Header
            view={view}
            setView={setView}
            showEncounter={showEncounters}
            handleCreateClick={() => {
              handleToggleShowDataEntrySidebar(!showDataEntrySidebar);
              setFormMode("create");
            }}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
          />
          <div className={`${mainContentWidth} h-full`}>
            <MainContent
              tabs={tabs}
              configData={configData}
              patientInformations={patientInformations}
              showEncounters={showEncounters}
              onTileClick={handleTileClick}
              handleToggleSoapNotes={handleToggleSoapNotes}
              view={view}
              setView={setView}
              searchTerm={searchTerm}
              handleShowProcedueOrders={handleShowProcedueOrders}
            />
          </div>
        </div>
        {showSoapNotes && (
          <SoapNotes
            onCloseSoapnote={handleToggleShowSideBar}
            onSoapnoteSubmit={handleSubmitSoapnotes}
            message={message}
            encounter={activeEncounter}
          />
        )}
        {showDataEntrySidebar && (
          <DataEntrySidebar
            onOpenChange={handleToggleShowDataEntrySidebar}
            patientInformations={patientInformations}
            selectedPatientInformation={selectedPatientInformation}
            pid={userId}
            username={userInformation[0].username}
            formMode={formMode}
            refreshData={refreshInformations}
            labsArr={LabsArr}
            setSelectedPatientInformation={setSelectedPatientInformation}
            setFormMode={setFormMode}
            labOrdersEncounterId={labOrdersEncounterId}
            handleToggleShowDataEntrySidebar={handleToggleShowDataEntrySidebar}
          />
        )}
      </main>
    </>
  );
}

export default App;
