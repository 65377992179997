import * as Dialog from "@radix-ui/react-dialog";
import {
  ICD10SearchSuggestionType,
  ICD10SearchSuggestionsType,
} from "entities/icdSearchSuggestion";
import { useGetData } from "hooks/useGetData";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { ICD10ModalSelectOptions } from "utils/constants";
import { CustomInput } from "../forms/InputWrapper";
import SelectInput from "../forms/SelectInput";
import Modal from "./Modal";

type ICD10ModalProps = {
  isOpen: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
  handleSearchSelect: (item: ICD10SearchSuggestionType) => void;
};

const ICD10Modal = (props: ICD10ModalProps) => {
  const { onOpenChange, isOpen, handleSearchSelect } = props;
  const [limit, setLimit] = useState("10");

  const [searchTerm, setSearchTerm] = useState("");

  const { isLoading, data } = useGetData<ICD10SearchSuggestionsType>(
    `/icd10.php/${searchTerm}`
  );

  const icd10Data = useMemo(() => {
    if (!searchTerm) return [];
    if (data) return data.slice(0, +limit);
    return [];
  }, [data, limit, searchTerm]);

  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Dialog.Title className="flex w-full items-center justify-center mb-4">
        <div className="bg-[#BD20A31F] rounded-lg h-[50px] text-sm font-bold flex px-4 items-center justify-center ">
          <h3>ICD-10 Diagnosis Section</h3>
        </div>
      </Dialog.Title>
      <div>
        <CustomInput
          type="search"
          name="search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="ICD-10 Search - Type to Search"
        />
      </div>
      <div className="mt-4 flex gap-6 items-center w-[200px]">
        <p>Show</p>
        <SelectInput
          options={ICD10ModalSelectOptions}
          name="icd10modal"
          value={limit}
          onChange={(e) => setLimit(e)}
        />
      </div>

      <div className="grid grid-cols-12 px-2 border-b-[#000000] border-b py-2 mt-6">
        <p className="font-bold text-sm  col-span-3">ICD-10 Code</p>
        <p className="font-bold text-sm col-span-9 line-clamp-1">Description</p>
      </div>

      {isLoading ? (
        // <Spinner size="3" />
        <p>loading</p>
      ) : (
        <div className="max-h-[300px] overflow-y-auto mt-2">
          {icd10Data.map((item) => (
            <button
              className="px-2 w-full h-[32px] border-b text-[#039ACA] hover:bg-gray-300 grid grid-cols-12 items-center text-left"
              key={item.dx_code}
              onClick={() => handleSearchSelect(item)}
            >
              <p className="col-span-3">{item.formatted_dx_code}</p>
              <p className="col-span-9 line-clamp-1 items-center">
                {item.short_desc}
              </p>
            </button>
          ))}
        </div>
      )}

      <div className="flex gap-4 w-full justify-end mt-6">
        <Dialog.Close>
          <button className="h-8 w-fit px-4 flex gap-2 items-center font-semibold text-sm rounded-lg bg-[#EF4444] text-white">
            <img
              src="/icons/cancel-icon-white.svg"
              alt="cancel"
              height={12}
              width={12}
            />
            Cancel/Exit
          </button>
        </Dialog.Close>
      </div>
    </Modal>
  );
};

export default ICD10Modal;
