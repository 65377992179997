import { useSetUrlState } from "hooks/useSetUrlState";
import { Dispatch, SetStateAction, useCallback, useMemo } from "react";

type HeaderProps = {
  view: string;
  setView: Dispatch<SetStateAction<"year" | "month" | "day">>;
  showEncounter: string;
  handleCreateClick: () => void;
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
};

type ToggleButtonProps = {
  isActive: boolean;
  name: string;
  onClick: () => void;
};
const ToggleButton = ({ isActive, name, onClick }: ToggleButtonProps) => {
  return (
    <button
      className={`w-[42px] h-[18px] ${
        isActive
          ? "text-white bg-[#A82784] font-medium rounded"
          : " font-semibold"
      } text-xs`}
      onClick={onClick}
    >
      {name}
    </button>
  );
};

const Header = ({
  view,
  setView,
  showEncounter,
  handleCreateClick,
  setSearchTerm,
  searchTerm
}: HeaderProps) => {
  const { setUrlState, deleteUrlState } = useSetUrlState();

  const iconPath = useMemo(
    () =>
      showEncounter === "true"
        ? "/icons/eye-closed-icon.svg"
        : "/icons/eye-open-icon.svg",
    [showEncounter]
  );

  const handleToggleEncounters = useCallback(() => {
    if (showEncounter === "true") {
      deleteUrlState("showEncounters");
    } else {
      setUrlState("showEncounters", "true");
    }
  }, [deleteUrlState, setUrlState, showEncounter]);

  return (
    <div className="w-full border-b  h-12 flex justify-between items-center sticky top-0 z-10 bg-white">
      <div className="bg-[#EFF1F3] h-[33px] px-[7px] rounded-lg ml-4 w-[146px] border border-[#E5E5E5] flex items-center justify-between">
        <ToggleButton
          onClick={() => setView("year")}
          name="Year"
          isActive={view === "year"}
        />
        <ToggleButton
          onClick={() => setView("month")}
          name="Month"
          isActive={view === "month"}
        />
        <ToggleButton
          onClick={() => setView("day")}
          name="Day"
          isActive={view === "day"}
        />
      </div>

      <form action="">
        <input
          type="search"
          id=""
          className="w-[200px] h-[30px] border text-xs px-2 rounded-lg"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </form>

      <div className="gap-2 flex items-center justify-between  mr-4">
        <button
          onClick={handleToggleEncounters}
          className="w-8 h-8 rounded-full border bg-[#EFF1F3] flex items-center justify-center"
        >
          <img
            src={iconPath}
            alt="Show Encounter"
            height={20}
            width={20}
            className=""
          />
        </button>
        <button onClick={handleCreateClick}>
          <img
            src="/icons/plus-icon-circle-dark.svg"
            alt="plus-button"
            height={45}
            width={45}
            className=""
          />
        </button>

        <div className="ml-6">
          <img
            src="/images/vehr-logo.png"
            alt="vher-logo"
            width={30}
            height={30}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
