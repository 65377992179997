import { CreateDataInput, CreateLabProcedureInputType, SoapnotesInputType } from "entities/create";
import { apiHTTPClient } from "lib/http-requester";
import { ENDPOINTS } from "utils/constants";


export class EntryService {
  static async saveEntries(payload: Partial<CreateDataInput>) {
    const res = await apiHTTPClient.post<any>(
      `${ENDPOINTS.createData}`,
      payload
    );
    return res;
  }
  static async saveLabProcedure(payload: Partial<CreateLabProcedureInputType>) {
    const res = await apiHTTPClient.post<any>(
      `${ENDPOINTS.saveLabProcedure}`,
      payload
    );
    return res;
  }
  static async updateLabProcedure(payload: Partial<CreateLabProcedureInputType>) {
    const res = await apiHTTPClient.update<any>(
      `${ENDPOINTS.saveLabProcedure}`,
      payload
    );
    return res;
  }
  static async updateEnteries(payload: Partial<CreateDataInput>) {
    const res = await apiHTTPClient.update<any>(
      `${ENDPOINTS.createData}`,
      payload
    );
    return res;
  }
  static async saveSoapnotes(payload: Partial<SoapnotesInputType>) {
    const res = await apiHTTPClient.post<any>(
      `${ENDPOINTS.creatSoapNotes}?pid=${payload.pid}&encounter_id=${payload.eid}`,
      payload
    );
    return res;
  }
}
