import { apiHTTPClient } from "lib/http-requester";
import { useCallback, useMemo } from "react";
import useSWR from "swr";
import { generateQueryString } from "utils/helpers";

export function useGetData<T>(
  endpoint: string | null,
  urlArgs: Record<string, string | number | boolean> = {},
  revalidate = true
) {
  const param = generateQueryString(urlArgs);
  const fetcher = async (url: string, params?: object) => {
    const res = await apiHTTPClient.axiosInstance.get(`${url}`, {
      params,
    });
    return res.data;
  };

  const url = param ? `${endpoint}?${param}` : endpoint;

  const { data, error, isLoading, mutate } = useSWR<T>(url, fetcher, {
    revalidateOnMount: revalidate,
    revalidateOnFocus: false,
  });

  const refetch = useCallback(() => {
    mutate();
  }, [mutate]);

  return useMemo(
    () => ({
      data,
      isLoading,
      error,
      refetch,
    }),
    [error, data, refetch, isLoading]
  );
}
