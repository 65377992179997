import clsx from "clsx";
import { useEffect } from "react";
import searchTextHL from "search-text-highlight";
import Highlighted from "./Highlighted";

type Tag = "h1" | "h2" | "h3" | "h4" | "h5" | "p" | "span" | "label" | "div";

export const weights = {
  bold: "font-bold",
  medium: "font-medium",
  normal: "font-normal",
  inherit: "",
};

const appTypography = {
  h1: "text-h1",
  pTinny: "text-xs",
  pSmall: "text-sm",
  pLarge: "text-lg",
};

const fontColors = {
  inherit: "",
};
const alignConfig = {
  left: "text-left",
  right: "text-right",
  center: "text-center",
};

const lineClamp = {
  clamp1: "line-clamp-1",
  none: "",
};

const transformations = {
  "rounded-l-lg": "rounded-l-lg",
};

export type TypographyProps = {
  type: keyof typeof appTypography;
  htmlTag: Tag;
  color?: keyof typeof fontColors;
  children: string;
  align?: keyof typeof alignConfig;
  weight?: keyof typeof weights;
  spaceAround?: boolean;
  id?: string;
  transform?: (keyof typeof transformations)[];
  clamp?: keyof typeof lineClamp;
  searchText?: string;
};

const Typography = (props: TypographyProps) => {
  const {
    align = "left",
    weight = "inherit",
    htmlTag,
    id,
    color = "inherit",
    spaceAround = false,
    transform = [],
    clamp,
    searchText = "",
  } = props;
  const font = appTypography[props.type];
  const el = document.createElement("span");
  useEffect(() => {
    el.innerHTML = searchTextHL.highlight(props.children, searchText, {
      hlClass: "bg-yellow-400",
    });
  }, [searchText, props.children, el]);

  const Component = htmlTag;
  return (
    <Component
      id={id}
      className={clsx(
        font,
        alignConfig[align],
        weights[weight],
        clamp ? lineClamp[clamp] : "",
        fontColors[color],
        spaceAround ? "mx-2" : "",
        ...transform
      )}
    >
      <Highlighted text={props.children} highlight={searchText} />
    </Component>
  );
};

export default Typography;
