import { useFormik } from "formik";
import { useMemo } from "react";
import { SoapnotesInputType } from "../entities/create";
import { EncounterDetails } from "../entities/issues";

type SoapNotesPropType = {
  onCloseSoapnote: () => void;
  onSoapnoteSubmit: (input: Partial<SoapnotesInputType>) => void;
  message: { message: string | null; isError: boolean };
  encounter?: EncounterDetails;
};

type FormInputProps = {
  title: string;
  name: string;
  value: string;
  onChange?: any;
};
const FormInput = (props: FormInputProps) => {
  const { value, title, name, onChange } = props;
  return (
    <div className="mb-4 border-[#D9D9D9] bg-[#F7F7F7] border rounded-xl h-[190px]">
      <div className="flex items-center justify-between w-full bg-[#A82784] h-9 rounded-t-xl px-4">
        <p className="text-white font-semibold">{title}</p>
        <img src="/icons/mic-icon.svg" alt="mic" width={16} height={16} />
      </div>
      <textarea
        name={name}
        value={value}
        onChange={onChange}
        className="p-2 h-[150px] active:outline-none active:border-none rounded-b-xl bg[#F7F7F7] outline-none text-sm w-full "
      ></textarea>
    </div>
  );
};

const SoapNotes = (props: SoapNotesPropType) => {
  const { onCloseSoapnote, onSoapnoteSubmit, message, encounter } = props;

  const initialValues = useMemo(() => {
    if (encounter && encounter.soap_notes.length) {
      return encounter.soap_notes[0];
    }
    return null;
  }, [encounter]);
  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: {
      subjective: initialValues?.subjective ?? "",
      objective: initialValues?.objective ?? "",
      assessment: initialValues?.assessment ?? "",
      plan: initialValues?.plan ?? "",
      reason: encounter?.reason ?? "",
    },
    onSubmit: async (values) => {
      onSoapnoteSubmit(values);
    },
    enableReinitialize: true,
  });
  return (
    <div className="h-screen flex gap-2 fixed right-0 top-12 ">
      <div className="w-[301px] border border-[#EFF1F3] shadow-lg rounded-lg px-4 pt-6 bg-[#EFF1F3]">
        <FormInput
          name="reason"
          value={values.reason}
          onChange={handleChange}
          title="REASON"
        />
      </div>
      <div className="h-screen border  w-[301px] border-[#EFF1F3] shadow-lg rounded-lg  px-4 pt-6 bg-[#EFF1F3]">
        <div>
          <div className="flex justify-between w-full items-center">
            <p className="text-xs font-medium leading-3">NOTE TAKING</p>

            <div className="flex items-center gap-4">
              <button type="button" onClick={onCloseSoapnote}>
                <img
                  src="/icons/close-icon-3.svg"
                  alt="close"
                  width={12}
                  height={12}
                />
              </button>
              <button type="button" onClick={() => handleSubmit()}>
                <img
                  src="/icons/circle-checked-icon.svg"
                  alt="close soapnotes"
                  width={17}
                  height={17}
                />
              </button>
            </div>
          </div>
          <h4 className="font-extrabold text-lg mt-2">SOAP</h4>
        </div>
        <div className="h-[80%] overflow-scroll mt-6">
          <FormInput
            name="subjective"
            value={values.subjective}
            onChange={handleChange}
            title="SUBJECTIVE"
          />
          <FormInput
            name="objective"
            value={values.objective}
            onChange={handleChange}
            title="OBJECTIVE"
          />
          <FormInput
            name="assessment"
            value={values.assessment}
            onChange={handleChange}
            title="ASSESSMENT & PLAN"
          />
          <FormInput
            name="plan"
            value={values.plan}
            onChange={handleChange}
            title="ORDERS"
          />

          {message.message && (
            <div
              className={`${
                message.isError ? "bg-red-500" : "bg-green-500"
              }text-white py-1 bg-green-500 rounded-lg items-center text-center relative`}
            >
              {message.message}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SoapNotes;
