import { ConfigType } from "entities/config";
import { EncounterDetails } from "entities/issues";
import SingleEncounter from "./SingleEncounter";
import { EncounterOverlayHeightType } from "./type";

type EncounterMonthProp = {
  encounter: EncounterDetails[];
  width: number;
  encounterHeight: number;
  handleToggleSoapNotes: (encounter: EncounterDetails) => void;
  encounterSectionHeight: EncounterOverlayHeightType;
  tests?: ConfigType["tests"];
  vitals?: ConfigType["vitals"];
  handleShowProcedueOrders: (encounterId: string) => void;
};

export const EncounterMonth = ({
  encounter,
  width,
  encounterHeight,
  handleToggleSoapNotes,
  encounterSectionHeight,
  vitals,
  tests,
  handleShowProcedueOrders
}: EncounterMonthProp) => {
  return (
    <div
      className="flex gap-6 h-full"
      style={{
        width: `${width}px`,
      }}
    >
      {encounter.map((item, i) => (
        <SingleEncounter
          encounter={item}
          key={item.id + item.pid + i}
          encounterHeight={encounterHeight}
          handleToggleSoapNotes={handleToggleSoapNotes}
          encounterSectionHeight={encounterSectionHeight}
          vitals={vitals}
          tests={tests}
          handleShowProcedueOrders={handleShowProcedueOrders}
        />
      ))}
    </div>
  );
};
