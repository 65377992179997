import { InfoTypes } from "entities/patientInformation";
import { getTabHeight } from "utils/helpers";
import { TabTypes } from "./types";

type SideBarPropType = {
  collapsed: boolean;
  toggleSidebar: () => void;
  tabs?: TabTypes[];
  toggleProblemGroups: (type: InfoTypes) => void;
};

const tabsToIconMapper: Record<string, string> = {
  problem: "/icons/problem-icon.svg",
  medication: "/icons/medication-icon.svg",
  allergy: "/icons/allergy-icon.svg",
  surgery: "/icons/surgery-icon.svg",
  dental: "/icons/dental-icon.svg",
  device: "/icons/devices-icon.svg",
  vitals: "/icons/vitals-icon.svg",
  "lab tests": "/icons/labs-icon.svg",
};

const SideBar = (props: SideBarPropType) => {
  const { collapsed, toggleSidebar, tabs, toggleProblemGroups } = props;

  return (
    <div
      className="lg:w-56 bg-[#A82784] py-4"
      style={{ width: `${collapsed ? "80px" : "168px"}` }}
    >
      <div>
        <img src="/icons/menu-icon.svg" alt="menu" width={48} height={48} />
      </div>
      <div className="min-h-screen pr-3 relative mt-4">
        <div className=" flex flex-col px-1 w-full">
          {tabs?.map((tab) => (
            <div
              className=""
              key={tab.name}
              style={{
                height: `${
                  getTabHeight(tab.divHeight, tab.isCollapsed).height
                }px`,
              }}
            >
              <button
                className={`${
                  collapsed
                    ? "min-w-[40px] justify-center"
                    : "w-full justify-between"
                } h-[42px] bg-[#BD20A36B] flex text-xs items-center px-1 text-white rounded-xl`}
                onClick={() => toggleProblemGroups(tab.type)}
              >
                <div className="flex gap-2 items-center">
                  <img
                    src={tabsToIconMapper[tab.tab_name.toLowerCase()]}
                    alt="caret-right"
                    width={20}
                    height={20}
                    className={`w-6 h-6 `}
                  />
                  {!collapsed ? tab.tab_name : ""}
                </div>
                {!collapsed && (
                  <img
                    src="/icons/caret-right-filled.svg"
                    alt="caret-right"
                    width={12}
                    height={12}
                    className={`w-3 h-3  ${tab.isCollapsed ? "rotate-90" : ""}`}
                  />
                )}
              </button>
            </div>
          ))}
        </div>
        <div
          className="w-4 fixed top-[50%] left-[128px]"
          style={{ left: `${collapsed ? "50px" : "128px"}` }}
        >
          <button
            className={`flex w-8 h-8 bg-white z-10 rounded-full justify-center items-center`}
            onClick={toggleSidebar}
            style={{ boxShadow: "0px 4.94px 4.94px 0px rgba(0, 0, 0, 0.3)" }}
          >
            <img
              src="/icons/caret-left.svg"
              alt="close-tab"
              height={15}
              width={10}
              className={`w-6 h-6  ${collapsed ? "rotate-180" : ""}`}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
