import ICD10Modal from "components/modal/ICD10Modal";
import ProceduresModal from "components/modal/ProceduresModal";
import CustomDatePicker from "components/utilities/DatePicker";
import { OrdersType } from "entities/create";
import { ICD10SearchSuggestionType } from "entities/icdSearchSuggestion";
import { ProcedureResponseType } from "entities/labs";
import { useFormik } from "formik";
import { forwardRef, useCallback, useImperativeHandle, useState } from "react";
import { ProcedureOrderInputs } from "utils/constants";
import { CustomTextArea, FormInputBox } from "./InputWrapper";
import SelectInput from "./SelectInput";
import { Options, ProcedureDataEntryChildrenHandler } from "./types";

type DiagonisisInputProps = {
  name?: string;
  disgonisis?: string;
  onDeleteClick?: (id?: number) => void;
  id?: number;
  onDiagonisisClick: (id?: number) => void;
  onProcedureClick: (id?: number) => void;
  handleAddClick?: () => void;
};

const DiagonisisInput = (props: DiagonisisInputProps) => {
  const {
    name,
    disgonisis,
    onDeleteClick,
    id,
    onDiagonisisClick,
    onProcedureClick,
    handleAddClick,
  } = props;
  return (
    <div className="h-fit flex gap-4 items-center mt-2 w-full">
      <div className="bg-[#BD20A31A] rounded-xl w-full flex gap-2 flex-col p-2">
        <FormInputBox title="Procedure Test">
          <button
            onClick={() => onProcedureClick(id)}
            className="rounded-lg w-full line-clamp-1 h-[40px] bg-white pl-8 pr-4 flex items-center text-xs relative"
          >
            <img
              src="/icons/search-icon.svg"
              alt="search"
              width={14}
              height={14}
              className="absolute left-2 top-[10px]"
            />
            {name ? (
              <p className="line-clamp-1">{name}</p>
            ) : (
              <p className="text-[#4D535894] font-medium">
                Click to select desired procedure
              </p>
            )}
          </button>
        </FormInputBox>
        <FormInputBox title="Diagnosis Code">
          <button
            onClick={() => onDiagonisisClick(id)}
            className="rounded-lg h-[40px] w-full bg-white pl-8 pr-4 flex items-center text-sm relative"
          >
            <img
              src="/icons/search-icon.svg"
              alt="search"
              width={14}
              height={14}
              className="absolute left-2 top-[10px]"
            />
            {disgonisis ? (
              <p className="line-clamp-1">ICD10: {disgonisis}</p>
            ) : (
              <p className="text-[#4D535894] line-clamp-1 font-medium">
                Click to select diagnosis code
              </p>
            )}
          </button>
        </FormInputBox>
      </div>
      {onDeleteClick ? (
        <button onClick={() => onDeleteClick(id)}>
          <img
            src="/icons/delete-icon.svg"
            alt="delete-icon"
            height={20}
            width={20}
          />
        </button>
      ) : (
        <div className="w-5 h-4">
          <button onClick={handleAddClick}>
            <img
              src="/icons/plus-icon.svg"
              alt="delete-icon"
              height={20}
              width={20}
            />
          </button>
        </div>
      )}
    </div>
  );
};

type ProcedureOrdersProps = {
  users: Options[];
  labs: Options[];
  labOrdersEncounterId: string | null;
};

type ModalModeType = "primary" | "orders";

const ProcedureOrders = forwardRef<
  ProcedureDataEntryChildrenHandler,
  ProcedureOrdersProps
>((props, ref) => {
  const { users, labs, labOrdersEncounterId } = props;

  const [showIcdModalOpen, setShowIcdModalOpen] = useState(false);
  const [showProcedureModalOpen, setShowProcedureModalOpen] = useState(false);
  const [icd10Mode, setIcd10Mode] = useState<ModalModeType>("primary");
  const [itemIndexToEdit, setItemIndexToEdit] = useState<number | null>(null);
  const [diagonisisArr, setDiagonisisArr] = useState<OrdersType[]>([]);
  const [newOrder, setNewOrder] = useState<OrdersType>({
    procedure_code: "",
    procedure_name: "",
    diagnoses: "",
    procedure_source: "1",
  });

  const onDeleteClick = useCallback(
    (id: number) => {
      const arrCopy = [...diagonisisArr];
      arrCopy.splice(id, 1);
      setDiagonisisArr(arrCopy);
    },
    [diagonisisArr]
  );

  const handleShowIcd10Modal = useCallback(
    (mode: ModalModeType, id?: number) => {
      setIcd10Mode(mode);
      if (id !== undefined) {
        setItemIndexToEdit(id);
      } else {
        setItemIndexToEdit(null);
      }
      setShowIcdModalOpen(true);
      setShowProcedureModalOpen(false);
    },
    []
  );

  const handleShowProcedureModal = useCallback(
    (mode: ModalModeType, id?: number) => {
      setIcd10Mode(mode);
      if (id !== undefined) {
        setItemIndexToEdit(id);
      } else {
        setItemIndexToEdit(null);
      }
      setShowIcdModalOpen(false);
      setShowProcedureModalOpen(true);
    },
    []
  );

  const { values, handleChange, setFieldValue, resetForm } = useFormik({
    initialValues: {
      // procedure_order_id: 87, //only include this in a PUT request
      patient_id: "",
      provider_id: "",
      date_ordered: "",
      date_collected: "",
      order_status: "", // 4 options: pending, completed, routed,  canceled
      lab_id: "",
      specimen_type: "", //leave this empty, dont show on UI
      specimen_location: "", //leave this empty, dont show on UI
      specimen_volume: "", //leave this empty, dont show on UI
      clinical_hx: "",
      order_diagnosis: "",
      specimen_fasting: "",
      order_psc: "",
      order_abn: "",
      procedure_order_type: "",
      encounter_id: "",
      activity: "",
      patient_instructions: "",
      order_priority: "",
      billing_type: "",
      history_order: "",
    },
    onSubmit: () => undefined,
  });

  useImperativeHandle(ref, () => ({
    submitProcedure: () => ({
      ...values,
      orders: diagonisisArr,
    }),
  }));

  const setPrimaryDiagonsis = useCallback(
    (item: ICD10SearchSuggestionType) => {
      setFieldValue("order_diagnosis", item.formatted_dx_code);
    },
    [setFieldValue]
  );
  const setPrimaryProcedure = useCallback(
    (item: ProcedureResponseType) => {
      setFieldValue("procedure_order_type", item.name);
    },
    [setFieldValue]
  );

  const handleSearchSelect = useCallback(
    (item: ICD10SearchSuggestionType) => {
      if (icd10Mode == "orders") {
        const arrCopy = [...diagonisisArr];
        if (itemIndexToEdit !== null) {
          arrCopy[itemIndexToEdit].diagnoses = item.formatted_dx_code;
          setDiagonisisArr(arrCopy);
        } else {
          const newOrderCopy = {
            ...newOrder,
            diagnoses: item.formatted_dx_code,
          };
          setNewOrder(newOrderCopy);
        }
      }
      if (icd10Mode == "primary") {
        setPrimaryDiagonsis(item);
      }

      setShowIcdModalOpen(false);
    },
    [diagonisisArr, itemIndexToEdit, newOrder, icd10Mode, setPrimaryDiagonsis]
  );

  const handleProcedureSelect = useCallback(
    (item: ProcedureResponseType) => {
      if (icd10Mode == "orders") {
        const arrCopy = [...diagonisisArr];
        if (itemIndexToEdit !== null) {
          arrCopy[itemIndexToEdit].procedure_code = item.procedure_code;
          arrCopy[itemIndexToEdit].procedure_name = item.name;
          setDiagonisisArr(arrCopy);
        } else {
          const newOrderCopy = {
            ...newOrder,
            procedure_code: item.procedure_code,
            procedure_name: item.name,
          };
          setNewOrder(newOrderCopy);
        }
      }
      if (icd10Mode == "primary") {
        setPrimaryProcedure(item);
      }
      setShowProcedureModalOpen(false);
    },
    [diagonisisArr, itemIndexToEdit, newOrder, icd10Mode, setPrimaryProcedure]
  );

  const handleAddClick = useCallback(() => {
    const arrCopy = [...diagonisisArr];
    arrCopy.push(newOrder);
    setDiagonisisArr(arrCopy);
    setNewOrder({
      procedure_code: "",
      procedure_name: "",
      diagnoses: "",
      procedure_source: "1",
    });
  }, [diagonisisArr, newOrder]);
  return (
    <>
      <>
        {labOrdersEncounterId ? (
          <div className="mt-4 h-[78%] overflow-y-scroll">
            <div>
              <p className=" text-base font-bold">
                Select Options for Current Order Or New Order
              </p>
              <div className="overflow-y-scroll bg-white border border-dashed rounded-lg mt-6 p-4">
                <form className="flex flex-col gap-4">
                  <FormInputBox title="Ordering Provider">
                    <SelectInput
                      options={users}
                      value={values.provider_id}
                      name="provider_id"
                      onChange={(e) => setFieldValue("provider_id", e)}
                    />
                  </FormInputBox>
                  <FormInputBox title="Order Date">
                    <div className="w-full bg-[#F7F7F7] border border-[#E7B9DF] pl-2 rounded-lg h-full">
                      <CustomDatePicker
                        date={values.date_ordered as any}
                        onChange={(date) => setFieldValue("date_ordered", date)}
                      />
                    </div>
                  </FormInputBox>
                  <FormInputBox title="Date Collected">
                    <div className="w-full bg-[#F7F7F7] border border-[#E7B9DF] pl-2 rounded-lg h-full">
                      <CustomDatePicker
                        date={values.date_collected as any}
                        onChange={(date) =>
                          setFieldValue("date_collected", date)
                        }
                      />
                    </div>
                  </FormInputBox>
                  <FormInputBox title="PSC Hold Order">
                    <SelectInput
                      options={ProcedureOrderInputs.orderPsc}
                      name="order_psc"
                      onChange={(e) => {
                        setFieldValue("order_psc", e);
                      }}
                      value={values.order_psc}
                    />
                  </FormInputBox>
                  <FormInputBox title="Labs">
                    <SelectInput
                      options={labs}
                      name="lab_id"
                      onChange={(e) => setFieldValue("lab_id", e)}
                      value={values.lab_id}
                    />
                  </FormInputBox>
                  <FormInputBox title="Fasting">
                    <SelectInput
                      options={ProcedureOrderInputs.fasting}
                      name="specimen_fasting"
                      onChange={(e) => setFieldValue("specimen_fasting", e)}
                      value={values.specimen_fasting}
                    />
                  </FormInputBox>
                  <FormInputBox title="Priority">
                    <SelectInput
                      options={ProcedureOrderInputs.priority}
                      name="order_priority"
                      onChange={(e) => setFieldValue("order_priority", e)}
                      value={values.order_priority}
                    />
                  </FormInputBox>
                  <FormInputBox title="ABN Status">
                    <SelectInput
                      options={ProcedureOrderInputs.abn}
                      name="order_abn"
                      onChange={(e) => setFieldValue("order_abn", e)}
                      value={values.order_abn}
                    />
                  </FormInputBox>
                  <FormInputBox title="Status">
                    <SelectInput
                      options={ProcedureOrderInputs.orderStatus}
                      name="order_status"
                      onChange={(e) => setFieldValue("order_status", e)}
                      value={values.order_status}
                    />
                  </FormInputBox>
                  <FormInputBox title="Billing">
                    <SelectInput
                      options={ProcedureOrderInputs.billing}
                      name="billing_type"
                      onChange={(e) => setFieldValue("billing_type", e)}
                      value={values.billing_type}
                    />
                  </FormInputBox>
                  <FormInputBox title="History Order">
                    <SelectInput
                      options={ProcedureOrderInputs.historyOrder}
                      name="history_order"
                      onChange={(e) => setFieldValue("history_order", e)}
                      value={values.history_order}
                    />
                  </FormInputBox>
                  <FormInputBox title="Clinical History">
                    <CustomTextArea
                      placeholder="Optional Note for Pharmacist (Unlimited Input field) *"
                      name="clinical_hx"
                      onChange={handleChange}
                      value={values.clinical_hx}
                    />
                  </FormInputBox>
                  <FormInputBox title="Patient Instruction">
                    <CustomTextArea
                      placeholder="Optional Note for Pharmacist (Unlimited Input field) *"
                      name="patient_instructions"
                      onChange={handleChange}
                      value={values.patient_instructions}
                    />
                  </FormInputBox>
                </form>
              </div>
            </div>

            <div className="mt-6">
              <p className=" text-xl font-bold">Procedure Order Details</p>
              <div className="mt-4 flex flex-col gap-4">
                <FormInputBox title="Primary Diagnosis">
                  <button
                    className="w-full text-left px-2 border border-[#E7B9DF] h-10 text-xs rounded-lg line-clamp-1 bg-[#F7F7F7]"
                    value={values.order_diagnosis}
                    onClick={() => handleShowIcd10Modal("primary")}
                    type="button"
                  >
                    {values.order_diagnosis}
                  </button>
                </FormInputBox>
                <FormInputBox title="Default Procedure Type">
                  <button
                    className="w-full text-left px-2 border border-[#E7B9DF] h-10 text-xs rounded-lg line-clamp-1 bg-[#F7F7F7]"
                    value={values.procedure_order_type}
                    onClick={() => handleShowProcedureModal("primary")}
                    type="button"
                  >
                    {values.procedure_order_type}
                  </button>
                </FormInputBox>
              </div>

              <div className="mt-6 border-t border-t-[#000000]">
                <div className="py-4">
                  {diagonisisArr.map((item, index) => (
                    <DiagonisisInput
                      key={index}
                      disgonisis={item.diagnoses}
                      name={item.procedure_name}
                      id={index}
                      onDeleteClick={(id) => onDeleteClick(id as number)}
                      onDiagonisisClick={() =>
                        handleShowIcd10Modal("orders", index)
                      }
                      onProcedureClick={() =>
                        handleShowProcedureModal("orders", index)
                      }
                    />
                  ))}
                </div>

                <div className="mt-4">
                  <DiagonisisInput
                    onDiagonisisClick={() => handleShowIcd10Modal("orders")}
                    onProcedureClick={() => handleShowProcedureModal("orders")}
                    handleAddClick={handleAddClick}
                    disgonisis={newOrder.diagnoses}
                    name={newOrder.procedure_name}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="mt-4 h-[78%]">
            <p>Encounter Id is required</p>
          </div>
        )}
      </>
      <ICD10Modal
        isOpen={showIcdModalOpen}
        onOpenChange={setShowIcdModalOpen}
        handleSearchSelect={handleSearchSelect}
      />
      <ProceduresModal
        isOpen={showProcedureModalOpen}
        onOpenChange={setShowProcedureModalOpen}
        handleSearchSelect={handleProcedureSelect}
        labId={values.lab_id}
      />
    </>
  );
});

ProcedureOrders.displayName = "ProcedureOrders";

export default ProcedureOrders;
