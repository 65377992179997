import { ConfigVitalsToUnitMapper, VitalsTileHeight } from "utils/constants";

type VitalsTileProps = {
  config: { id: number; name: string };
};

const VitalsTile = (props: VitalsTileProps) => {
  const { config } = props;
  return (
    <div
      className="w-full text-right border border-t-[#040E1C] px-2"
      style={{ height: `${VitalsTileHeight}px` }}
    >
      {config?.name} {ConfigVitalsToUnitMapper[config?.name]}
    </div>
  );
};

export default VitalsTile;
