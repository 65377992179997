import { ConfigType } from "entities/config";
import { EncounterDetails } from "entities/issues";
import { useMemo } from "react";
import { VitalsTileHeight } from "utils/constants";
import { getLabInformation, getVitalsInformation } from "utils/helpers";
import EncounterPopoverContent from "../popover/EncounterPopoverContent";
import CustomTooltip from "../popover/Tooltip";
import { EncounterOverlayHeightType } from "./type";

type SingleEncounterProp = {
  encounter: EncounterDetails;
  encounterHeight: number;
  handleToggleSoapNotes: (encounter: EncounterDetails) => void;
  encounterSectionHeight: EncounterOverlayHeightType;
  tests?: ConfigType["tests"];
  vitals?: ConfigType["vitals"];
  handleShowProcedueOrders: (encounterId: string) => void;
};

const SingleEncounter = ({
  encounter,
  handleToggleSoapNotes,
  encounterSectionHeight,
  tests,
  vitals,
  handleShowProcedueOrders,
}: SingleEncounterProp) => {

  const encounterType = useMemo(() => {
    if (
      encounter.class_code == "IMP" ||
      encounter.class_code == "NONAC" ||
      encounter.class_code == "ACUTE"
    )
      return "IPD";
    return "OPD";
  }, [encounter]);

  return (
    <CustomTooltip
      title={
        <EncounterPopoverContent
          encounter={encounter}
          vitals={vitals}
          tests={tests}
          handleCreateLabOrderClick={handleShowProcedueOrders}
        />
      }
      placement="left-start"
    >
      <div
        className="w-[100px] h-full cursor-pointer  hover:bg-[#BD20A329] flex flex-col"
        onClick={() => handleToggleSoapNotes(encounter as any)}
        role="button"
      >
        {/* rest of the encounter grid */}
        <div
          className={`text-center border ${
            encounterType == "IPD" ? "bg-[#BD20A329]" : "bg-[#29AAE129]"
          } border-x `}
          // key={i}
          style={{
            height: encounterSectionHeight.patientIssueSection,
          }}
        ></div>

        {/* //vitals */}
        <div
          className={`text-center border ${
            encounterType == "IPD" ? "bg-[#BD20A329]" : "bg-[#29AAE129]"
          } border-x`}
          // key={i}
          style={{
            height: encounterSectionHeight.vitalsSection,
          }}
        >
          {encounterSectionHeight.vitalsSection > 80 && (
            <>
              {getVitalsInformation(encounter)
                .reverse()
                .map((data, index) => (
                  <div
                    key={index + data}
                    className="text-right pr-1 text-black/75 font-black border-x text-sm  line-clamp-1 w-[100px]"
                    style={{
                      height: VitalsTileHeight,
                    }}
                  >
                    {data}
                  </div>
                ))}
            </>
          )}
        </div>

        {/* Lab */}
        {encounterSectionHeight.labSection > 90 && (
          <>
            {getLabInformation(encounter)
              .reverse()
              .map((data, index) => (
                <div
                  key={index + data}
                  className={`text-right pr-1 text-black/75 font-black border-x text-sm line-clamp-1 ${
                    encounterType == "IPD" ? "bg-[#BD20A329]" : "bg-[#29AAE129]"
                  }`}
                  style={{
                    height: VitalsTileHeight,
                  }}
                >
                  {data}
                </div>
              ))}
          </>
        )}
      </div>
    </CustomTooltip>
  );
};

export default SingleEncounter;
