import dayjs from "dayjs";
import { EncounterDetails } from "entities/issues";

type EncountersTabProps = {
  encounter: EncounterDetails;
};

const EncountersTab = ({ encounter }: EncountersTabProps) => {
  return (
    <div className={`grid grid-cols-2`}>
      <div className="flex flex-col w-full space-y-4 mx-4 my-2">
        <div className="flex w-full text-sm">
          <div className="mr-2">Reasons:</div>
          <div className=" font-sfuidisplayBlack flex-wrap mr-2">
            {encounter.reason}
          </div>
        </div>
        <div className="flex w-full text-sm">
          <div className="mr-2">Date of Dx (mm-dd-yyyy): </div>
          <div className=" font-sfuidisplayBlack flex-wrap mr-2">
            {dayjs(encounter.date).format("MM-DD-YYYY")}
          </div>
        </div>
        <div className="flex w-full text-sm">
          <div className="mr-2">Date onset:</div>
          <div className=" font-sfuidisplayBlack flex-wrap mr-2">-</div>
        </div>
      </div>

      <div className="flex flex-col w-full space-y-4 mx-4 my-2">
        <div className="flex w-full text-sm">
          <div className="mr-2">Billing Note:</div>
          <div className=" font-sfuidisplayBlack flex-wrap mr-2">
            {encounter.billing_note}
          </div>
        </div>
        <div className="flex w-full text-sm">
          <div className="mr-2">Facility:</div>
          <div className=" font-sfuidisplayBlack flex-wrap mr-2">
            {encounter.facility}
          </div>
        </div>
        <div className="flex w-full text-sm">
          <div className="mr-2">Provider ID:</div>
          <div className=" font-sfuidisplayBlack flex-wrap mr-2">
            {encounter.provider_id}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EncountersTab;
