import { ConfigType } from "entities/config";
import { EncounterDetails } from "entities/issues";

type LabsDataProps = {
  labs?: ConfigType["tests"];
  labsData: EncounterDetails["labs"];
  onCreateClick: () => void;
};

const LabsData = ({ labs, labsData, onCreateClick }: LabsDataProps) => {
  return (
    <div className={`flex w-full flex-col`}>
      <button
        onClick={onCreateClick}
        className="h-[29px] w-fit px-4 mb-4 font-semibold text-sm rounded-lg bg-[#29AAE1] text-white"
      >
        Create Lab Order
      </button>
      <table className="min-w-full px-2  text-sm">
        <thead className={`bg-red-50`}>
          <tr className={`flex w-full py-2`}>
            {labs?.map((data, i) => (
              <th
                className={`w-24 px-6 py-4 items-bottom overflow-hidden justify-bottom font-sfuidisplayBlack`}
                key={i}
              >
                {data.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {labsData &&
            labsData.map((labs, index) => (
              <tr
                className={`flex w-full py-2 ${
                  index % 2 == 0 ? "bg-gray-50" : "bg-gray-100"
                }`}
                key={index}
              >
                <td
                  className={`w-24 px-2 items-center text-center overflow-hidden justify-center text-sm`}
                >
                  {labs.result_text}
                </td>
                <td
                  className={`w-24 px-2 items-center text-center overflow-hidden justify-center text-sm`}
                >
                  {labs.range}
                </td>
                <td
                  className={`w-24 px-2 items-center text-center overflow-hidden justify-center text-sm`}
                >
                  {labs.result}
                </td>
                <td
                  className={`w-24 px-2 items-center text-center overflow-hidden justify-center text-sm`}
                >
                  {labs.result_status}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default LabsData;
