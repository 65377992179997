import ClickAwayListener from "react-click-away-listener";
import { HexColorPicker } from "react-colorful";

type ColorPickerProps = {
  onColorClick: (color: string) => void;
  color: string;
  onClose: () => void;
};

const ColorPicker = ({ onClose, onColorClick, color }: ColorPickerProps) => {
  return (
    <div className="fixed bg-black bg-opacity-25 h-full w-full top-0 left-0 z-10 overflow-y-auto font-sfuidisplay">
      <div className="grid h-screen place-items-center px-1">
        <div className="w-full m-auto bg-white overflow-hidden max-w-md ">
          <ClickAwayListener onClickAway={onClose}>
            <div className="flex flex-col w-full justify-center items-center">
              <HexColorPicker
                style={{ width: `500px` }}
                color={color}
                onChange={onColorClick}
              />
              <div
                className="uppercase py-1 cursor-pointer w-full text-center font-sfuidisplayBlack"
                onClick={onClose}
              >
                done
              </div>
            </div>
          </ClickAwayListener>
        </div>
      </div>
    </div>
  );
};

export default ColorPicker;
