import { ConfigType } from "entities/config";
import { ExtendedInfoType } from "../types";

import { EncounterDetails } from "entities/issues";
import { InfoTypes } from "entities/patientInformation";
import { DataTile } from "./Tile";
import VitalsTile from "./VitalsTile";

type TileGroupProps = {
  isCollapsed: boolean;
  groupData: ExtendedInfoType[];
  tests?: ConfigType["tests"];
  vitals?: ConfigType["vitals"];
  type: InfoTypes;
  maxWidth: number;
  groups: string[];
  encounters: Record<string, EncounterDetails[]>;
  view: "year" | "month" | "day";
  onTileClick: (patientInfo?: ExtendedInfoType) => void;
  searchTerm: string;
};

const TileGroup = (props: TileGroupProps) => {
  const {
    groupData,
    type,
    vitals,
    tests,
    isCollapsed,
    groups,
    maxWidth,
    encounters,
    view,
    onTileClick,
    searchTerm,
  } = props;
  return (
    <div
      className="w-full flex flex-col justify-end items-end bg-white mb-[15px]"
      style={{ minWidth: maxWidth }}
    >
      {isCollapsed ? (
        <div className="">
          <DataTile
            width={maxWidth}
            groups={[]}
            type={type}
            encounters={encounters}
            view={view}
            onTileClick={onTileClick}
            searchTerm={searchTerm}
          />
        </div>
      ) : (
        <div className="flex flex-col justify-between w-full">
          {type == "vitals" ? (
            <div className="w-full">
              {vitals?.map((data, i) => (
                <VitalsTile key={data.name + i} config={data} />
              ))}
            </div>
          ) : type == "tests" ? (
            <div className="w-full">
              {tests?.map((data, i) => (
                <VitalsTile key={data.name + i} config={data} />
              ))}
            </div>
          ) : (
            <div className="flex flex-col min-h-14 w-full">
              {groupData.length !== 0 ? (
                <>
                  {groupData?.map((data, i) => (
                    <DataTile
                      key={data.id + data.name + i}
                      data={data}
                      color={data.color}
                      width={maxWidth}
                      groups={groups}
                      encounters={encounters}
                      view={view}
                      onTileClick={onTileClick}
                      searchTerm={searchTerm}
                    />
                  ))}
                </>
              ) : (
                <DataTile
                  width={maxWidth}
                  groups={[]}
                  type={type}
                  encounters={encounters}
                  view={view}
                  onTileClick={onTileClick}
                  searchTerm={searchTerm}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TileGroup;
