import { Dropdown } from "entities/dropDownInformation";
import { ICD10SearchSuggestionType } from "entities/icdSearchSuggestion";
import { PatientInformationsType } from "entities/patientInformation";
import { useFormik } from "formik";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import ICD10Modal from "../modal/ICD10Modal";
import { ExtendedInfoType } from "../types";
import ColorPicker from "../utilities/ColorPicker";
import CustomDatePicker from "../utilities/DatePicker";
import { CustomInput, CustomTextArea, FormInputBox } from "./InputWrapper";
import SelectInput from "./SelectInput";
import { DataEntryChildrenHandler, Options } from "./types";
import { getRandomHexColor } from "utils/helpers";

type MedicationFormProps = {
  occurances: Options[];
  outcomes: Options[];
  verifications: Options[];
  medicationFavourites?: Dropdown[];
  medUsage: Options[];
  intent: Options[];
  patientInformations: Options[];
  selectedPatientInformation: ExtendedInfoType | null;
  referalSource: Options[];
  patient?: PatientInformationsType;
};

const MedicationForm = forwardRef<
  DataEntryChildrenHandler,
  MedicationFormProps
>((props, ref) => {
  const {
    occurances,
    outcomes,
    medUsage,
    intent,
    verifications,
    medicationFavourites,
    selectedPatientInformation,
    patientInformations,
    referalSource,
    patient,
  } = props;

  const [color, setColor] = useState(getRandomHexColor());
  const [showColorPicker, setShowColorPicker] = useState(false);

  const [showIcdModalOpen, seShowIcdModalOpen] = useState(false);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const handleColorClick = useCallback((color: string) => {
    setColor(color);
    setShowColorPicker(false);
  }, []);

  const handleColorClose = useCallback(() => {
    setShowColorPicker(false);
  }, []);

  useEffect(() => {
    if (selectedPatientInformation) {
      if (selectedPatientInformation.begdate) {
        setStartDate(
          selectedPatientInformation.begdate.split(" ")[0] as unknown as Date
        );
      }
      if (selectedPatientInformation.enddate) {
        setEndDate(
          selectedPatientInformation.enddate.split(" ")[0] as unknown as Date
        );
      }
      if (selectedPatientInformation.color) {
        setColor(selectedPatientInformation.color);
      }
    }
  }, [selectedPatientInformation]);

  const { values, handleChange, setFieldValue, resetForm } = useFormik({
    initialValues: {
      medicationFavourites: "",
      name_display: selectedPatientInformation?.name ?? "",
      icd_10: selectedPatientInformation?.diagnosis ?? "",
      occurance: selectedPatientInformation?.occurrence ?? "",
      verification: selectedPatientInformation?.verification ?? "",
      referredBy: selectedPatientInformation?.referredby ?? "",
      comments: selectedPatientInformation?.comments ?? "",
      outcome: selectedPatientInformation?.outcome ?? "",
      destination: selectedPatientInformation?.destination ?? "",
      intent: "",
      medUsage: ""
    },
    enableReinitialize: true,
    onSubmit: () => undefined,
  });

  useImperativeHandle(ref, () => ({
    submitData: () => ({
      ...values,
      color,
      start_date: startDate,
      end_date: endDate,
      icd_10_diagnoses: [values.icd_10],
      type: "medication",
    }),
  }));

  const clearFields = useCallback(() => {
    resetForm();
    setStartDate(null);
    setEndDate(null);
    setColor("");
  }, [resetForm]);

  const handleIcd10SearchSelect = useCallback(
    (data: ICD10SearchSuggestionType) => {
      setFieldValue("icd_10", "ICD10:" + data.formatted_dx_code);
      seShowIcdModalOpen(false);
    },
    [setFieldValue]
  );

  const handleSelectPatientInformation = useCallback(
    (code: string) => {
      const item = patient?.find((info) => info.diagnosis == code);
      if (!item) {
        clearFields();
        return;
      }
      setFieldValue("name_display", item.name ?? "");
      setColor(item.color ?? "");
      setFieldValue("ICD10", item.diagnosis ?? "");
      setStartDate(item.begdate.split(" ")[0] as unknown as Date);
      setEndDate(item.enddate?.split(" ")[0] as unknown as Date);
      setFieldValue("referredBy", item.referredby ?? "");
      setFieldValue("verification", item.verification ?? "");
      setFieldValue("occurance", item.occurrence ?? "");
      setFieldValue("outcome", item.outcome ?? "");
      setFieldValue("comments", item.comments ?? "");
      setFieldValue("destination", item.destination ?? "");
    },
    [setFieldValue, patient, clearFields]
  );

  const handleShowIcd10Modal = useCallback(() => {
    seShowIcdModalOpen(true);
  }, []);

  const onFavouriteClick = useCallback(
    (item: Dropdown) => {
      setFieldValue("icd_10", item.codes ?? "");
      setFieldValue("medicationFavoutites", item.name);
      setFieldValue("name_display", item.name);
    },
    [setFieldValue]
  );

  return (
    <>
      <form className=" flex flex-col gap-4">
        <FormInputBox title="Favourites / Previous">
          <div className="h-[77px] bg-[#F7F7F7] border border-[#E7B9DF] w-full overflow-y-scroll rounded-lg">
            {medicationFavourites?.map((item) => (
              <p
                onClick={() => onFavouriteClick(item)}
                key={item.id}
                className="hover:bg-gray-300 text-xs font-semibold hover:cursor-pointer items-center flex px-4 h-[25px]"
              >
                {item.name}
              </p>
            ))}
          </div>
        </FormInputBox>
        <FormInputBox title="Current ICD-10">
          <SelectInput
            options={patientInformations}
            name=""
            onChange={(e) => handleSelectPatientInformation(e)}
          />
        </FormInputBox>
        <FormInputBox title="Medications Display *">
          <CustomInput
            type="text"
            name="name_display"
            value={values.name_display}
            onChange={handleChange}
            placeholder="Free text entry or selected from above"
          />
        </FormInputBox>
        <FormInputBox title="Associated ICD-10 *">
          <button
            className="w-full text-left border border-[#E7B9DF] px-2 h-10 text-xs rounded-lg line-clamp-1 bg-[#F7F7F7]"
            value={values.icd_10}
            onClick={handleShowIcd10Modal}
            type="button"
          >
            {values.icd_10 ? (
              <p>{values.icd_10}</p>
            ) : (
              <p className="text-[10px]">
                ICD-10 Search - Click to Launch, or Add from Dropdown
              </p>
            )}
          </button>
        </FormInputBox>
        <FormInputBox title="Color">
          <button
            className="bg-[#F7F7F7] border border-[#E7B9DF] px-2 rounded-lg text-center w-full h-10 text-white flex items-center gap-2"
            onClick={(e) => {
              e.preventDefault();
              setShowColorPicker(true);
            }}
          >
            {color && (
              <div
                className="w-5 h-5 rounded"
                style={{
                  backgroundColor: color,
                }}
              />
            )}
            <p className="text-xs text-black">
              {color ? color : "Select color"}
            </p>
          </button>
        </FormInputBox>
        <FormInputBox title="Start Date ">
          <div className="w-full bg-[#F7F7F7] border border-[#E7B9DF] pl-2 rounded-lg h-full">
            <CustomDatePicker
              date={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </div>
        </FormInputBox>
        <FormInputBox title="End Date  (Defaults to 2 wks if Acute, N/A is Cronic) ">
          <div className="w-full bg-[#F7F7F7] border border-[#E7B9DF] pl-2 rounded-lg h-full">
            <CustomDatePicker
              date={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </div>
        </FormInputBox>
        <FormInputBox title="Occurrence ">
          <SelectInput
            options={occurances}
            value={values.occurance}
            name="occurance"
            onChange={(e) => setFieldValue("occurance", e)}
          />
        </FormInputBox>
        <FormInputBox title="Verification ">
          <SelectInput
            options={verifications}
            value={values.verification}
            name="verification"
            onChange={(e) => setFieldValue("verification", e)}
          />
        </FormInputBox>

        <FormInputBox title="Referred By">
          <SelectInput
            options={referalSource}
            value={values.referredBy}
            name="referredBy"
            onChange={(e) => setFieldValue("referredBy", e)}
          />
        </FormInputBox>
        <FormInputBox title="Comments">
          <CustomTextArea
            placeholder="Optional"
            name="comments"
            value={values.comments}
            onChange={handleChange}
          />
        </FormInputBox>

        <FormInputBox title="Destination">
          <CustomTextArea
            placeholder="optional"
            name="destination"
            value={values.destination}
            onChange={handleChange}
          />
        </FormInputBox>

        <FormInputBox title="Outcomes ">
          <SelectInput
            options={outcomes}
            value={values.outcome}
            name="outcome"
            onChange={(e) => setFieldValue("outcome", e)}
          />
        </FormInputBox>

        <FormInputBox title="Med Usage ">
          <SelectInput
            options={medUsage}
            value={values.medUsage}
            name="medUsage"
            onChange={(e) => setFieldValue("medUsage", e)}
          />
        </FormInputBox>

        <FormInputBox title="Medication Request Intent ">
          <SelectInput
            options={intent}
            value={values.intent}
            name="intent"
            onChange={(e) => setFieldValue("intent", e)}
          />
        </FormInputBox>
      </form>
      {showColorPicker && (
        <ColorPicker
          color={color}
          onClose={handleColorClose}
          onColorClick={handleColorClick}
        />
      )}
      <ICD10Modal
        isOpen={showIcdModalOpen}
        onOpenChange={seShowIcdModalOpen}
        handleSearchSelect={handleIcd10SearchSelect}
      />
    </>
  );
});

MedicationForm.displayName = "MedicationForm";

export default MedicationForm;
