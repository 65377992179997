import dayjs from "dayjs";
import { ExtendedInfoType } from "../types";

type DataPopoverContentProps = {
  data?: ExtendedInfoType;
};

const DataPopoverContent = (props: DataPopoverContentProps) => {
  const { data } = props;
  return (
    <>
      <div className="w-[600px] m-auto bg-primaryRedBgColor relative border-0 rounded-xl border-black overflow-hidden md:max-w-md ">
        <div className="md:flex">
          <div className="flex flex-col w-full space-y-4 mx-2 my-2">
            <div className="flex w-full text-sm">
              <div className="mr-2">Problem:</div>
              <div className=" text-black flex-wrap mr-2">{data?.name}</div>
            </div>
            <div className="flex w-full text-sm">
              <div className="mr-2">ICD-10 Coded as :</div>
              <div className=" font-sfuidisplayBlack flex-wrap mr-2">
                {data?.diagnosis}
              </div>
            </div>
            <div className="flex w-full text-sm">
              <div className="mr-2">Display As:</div>
              <div className=" font-sfuidisplayBlack mr-2">{data?.name}</div>
            </div>
            <div className="flex w-full text-sm">
              <div className="mr-2">Date of onset diagnosis:</div>
              <div className=" font-sfuidisplayBlack mr-2">
                {dayjs(data?.begdate.split(" ")[0]).format("DD-MM-YYYY")}
              </div>
            </div>
            <div className="flex w-full text-sm">
              <div className="mr-2">End date:</div>
              <div className=" font-sfuidisplayBlack mr-2">
                {data?.enddate && dayjs(data.enddate.split(" ")[0]).format("DD-MM-YYYY")}
              </div>
            </div>
            <div className="flex w-full text-sm">
              <div className="mr-2">Last Addressed:</div>
              <div className=" font-sfuidisplayBlack mr-2">
                {dayjs(data?.modifydate.split(" ")[0]).format("DD-MM-YYYY")}
              </div>
            </div>
            <div className="flex w-full text-sm">
              <div className="mr-2">Comments:</div>
              <div className=" font-sfuidisplayBlack">{data?.comments}</div>
            </div>

            <div className="flex w-full text-sm">
              <div className="mr-2">Linked Medications:</div>
              <div className=" font-sfuidisplayBlack">-</div>
            </div>
            <div className="flex w-full text-sm">
              <div className="mr-2">Last Editor:</div>
              <div className=" font-sfuidisplayBlack">{data?.user}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DataPopoverContent;
