import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import * as Select from "@radix-ui/react-select";
import clsx from "clsx";
import { useMemo } from "react";
import { Options } from "./types";

const colors = {
  white: "text-white",
  black: "text-black",
};

const backgroundColor = {
  gray: "bg-[#F7F7F7]",
  pink: "bg-[#BD20A3]",
};

type SelectProps = {
  options: Options[];
  onChange: (item: any) => void;
  value?: string;
  name: string;
  placeholder?: string;
  background?: keyof typeof backgroundColor;
  textColor?: keyof typeof colors;
};

type SelectItemProps = {
  option: Options;
};
const SelectItem = ({ option }: SelectItemProps) => {
  return (
    <Select.Item
      className="text-xs border-b leading-none text-violet11 z-10 hover:bg-slate-100 hover:outline-none hover:border-none h-[30px] px-4 cursor-pointer flex items-center mb-2"
      value={option.value ? option.value : option.label}
    >
      <Select.ItemText>{option.label}</Select.ItemText>
    </Select.Item>
  );
};

const SelectInput = (props: SelectProps) => {
  const {
    onChange,
    options,
    background = "gray",
    textColor = "black",
    value,
  } = props;

  const valueLabel = useMemo(() => {
    const option = options.find((option) => option.value == value);
    if (option) return option.label;
    return value;
  }, [value, options]);

  return (
    <Select.Root  onValueChange={onChange}>
      <Select.Trigger
        className={clsx(
          "inline-flex w-full items-center justify-between px-4 h-10 text-xs leading-none rounded-lg z-10 border border-[#E7B9DF] outline-none",
          colors[textColor],
          backgroundColor[background]
        )}
        aria-label="select"
      >
        <Select.Value placeholder={valueLabel || "Select"}>
          {valueLabel}
        </Select.Value>
        <Select.Icon asChild className="text-violet11">
          <ChevronDownIcon />
        </Select.Icon>
      </Select.Trigger>
      <Select.Portal>
        <Select.Content className="mt-6 overflow-hidden max-h-[var(--radix-select-content-available-height)] w-full z-10 bg-[#F7F7F7] rounded-md shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)]">
          <Select.ScrollUpButton className="flex items-center justify-center h-[25px] bg-white text-violet11 cursor-default">
            <ChevronUpIcon />
          </Select.ScrollUpButton>
          <Select.Viewport>
            {options.map((item, i) => (
              <SelectItem key={i} option={item} />
            ))}
          </Select.Viewport>
          <Select.ScrollDownButton className="flex items-center justify-center h-[25px] bg-white text-violet11 cursor-default">
            <ChevronDownIcon />
          </Select.ScrollDownButton>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};

export default SelectInput;
