import { useLocation, useNavigate, useSearchParams } from "react-router-dom";


export const useSetUrlState = () => {
  // const router = useRouter();
  // const pathname = usePathname();
  // const searchParams = useSearchParams();

  // const current = new URLSearchParams(Array.from(searchParams.entries()));

  const navigate = useNavigate(); // For navigation
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const pathname = location.pathname;

  const current = new URLSearchParams(Array.from(searchParams.entries()));


  return {
    setUrlState: (state: string, value: string) => {
      const urlState = current.get(state);
      if (urlState && urlState === value) {
        current.delete(state);
      } else {
        current.set(state, value);
      }
      const search = current.toString();
      const query = search ? `?${search}` : "";

      navigate(`${pathname}${query}`);
    },
    
    setMultipleUrlState: (obj: Record<string, string>) => {
      const states = Object.keys(obj);

      states.forEach((state) => {
        const urlState = current.get(state);
        if (urlState && urlState === obj[state]) {
          current.delete(state);
        } else {
          current.set(state, obj[state]);
        }
      });

      const search = current.toString();
      const query = search ? `?${search}` : "";

      navigate(`${pathname}${query}`);
    },

    deleteUrlState: (state: string) => {
      const urlState = current.get(state);
      if (urlState) {
        current.delete(state);
        const search = current.toString();
        const query = search ? `?${search}` : "";

        navigate(`${pathname}${query}`);
      }
    },
  };
};



