
import { ExtendedInfoType } from "components/types";
import { IssuesResponseType, EncounterDetails } from "entities/issues";
import { SinglePatientInfo, InfoTypes } from "entities/patientInformation";
import { EncounterWidth, TileYearWidth } from "utils/constants";
import { useEffect, useMemo, useState } from "react";


export default function useDayTimeline(
  patientInformations?: SinglePatientInfo[],
  issues?: IssuesResponseType
) {
  const [allDays, setAllDays] = useState<string[]>([]);
  const [maxDaysWidth, setMaxDaysWidth] = useState(0);
  const [maxEncounterWidth, setMaxEncounterWidth] = useState(0);

  useEffect(() => {
    const obj: Record<string, SinglePatientInfo[]> = {};
    let numberOfItems = 0;
    patientInformations?.forEach((data) => {
      if (data) {
        const splittedDate = data.begdate.split(" ");
        const encounterDay = splittedDate[0];

        if (obj[encounterDay]) {
          obj[encounterDay].push(data);
        } else {
          obj[encounterDay] = [data];
        }
        numberOfItems++;
      }
    });

    const distinctDays = Object.keys(obj);

    setMaxDaysWidth(numberOfItems * EncounterWidth + distinctDays.length * 200);
  }, [patientInformations]);

  const getDaysBetween = (start: string, end: string): string[] => {
    const startDate = new Date(start);
    const endDate = new Date(end);

    const days = [];

    while (startDate <= endDate) {
      const month = startDate.getMonth() + 1; // getMonth returns 0-indexed month
      const day = startDate.getDate(); // getDate returns the day of the month
      const year = startDate.getFullYear();

      const formattedDay = day < 10 ? `0${day}` : day;
      const formattedMonth = month < 10 ? `0${month}` : month; // add leading zero if needed

      days.push(`${year}-${formattedMonth}-${formattedDay}`);

      // Move to the next day
      startDate.setDate(startDate.getDate() + 1);
    }

    return days;
  };

  const groupPatientData = useMemo(() => {
    const groupedData: Record<InfoTypes, ExtendedInfoType[]> = {
      allergy: [],
      dental: [],
      medical_problem: [],
      medication: [],
      surgery: [],
      tests: [],
      vitals: [],
      medical_device: [],
    };
    const date = new Date();
    const dateString = date.toISOString();

    const yearAndMonth = `${dateString.split("T")[0]}`;

    const newMonths = new Set(allDays);

    if (patientInformations) {
      patientInformations.forEach((info) => {
        const beginingDate = info?.begdate.split(" ")[0];
        let endDate = info.enddate ? info.enddate.split(" ")[0] : yearAndMonth;

        if (
          info.type === "surgery" ||
          info.type === "allergy" ||
          info.type === "medical_device" ||
          info.type === "dental"
        ) {
          endDate = yearAndMonth;
        }

        newMonths.add(beginingDate);
        newMonths.add(endDate);
        const days = getDaysBetween(beginingDate, endDate);

        groupedData[info.type].push({
          ...info,
          enddate: info.enddate
            ? info.enddate.split(" ")[0]
            : date.toISOString().split("T")[0],
          tileWidth: TileYearWidth,
          end: endDate,
          start: beginingDate,
          contigiousDates: days,
        });
      });
    }

    setAllDays(Array.from(newMonths).sort());

    return groupedData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientInformations]);

  const daysEncounter = useMemo(() => {
    const encounters: Record<string, EncounterDetails[]> = {};

    allDays.forEach((day) => {
      if (day !== "NaN") encounters[day] = [];
    });

    if (issues) {
      issues.encounters.forEach((encounter, i) => {
        const day = encounter.date.split(" ")[0];
        if (day !== "0000-00-00") {
          encounters[day] = [...encounter.encounters];
        }
      });
    }

    return encounters;
  }, [issues, allDays]);

  useEffect(() => {
    const dayEncounterKeys = Object.keys(daysEncounter);
    let width = 0;
    for (const day of dayEncounterKeys) {
      if (daysEncounter[day as InfoTypes]) {
        width += daysEncounter[day as InfoTypes].length * EncounterWidth + 300;
      } else {
        width += TileYearWidth;
      }
    }
    setMaxEncounterWidth(width);
  }, [daysEncounter]);

  return {
    days: Object.keys(daysEncounter).sort(),
    maxDaysWidth,
    dayGroupPatientData: groupPatientData,
    daysEncounter,
    maxDaysEncounterWidth: maxEncounterWidth,
  };
}
