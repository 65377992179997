import { ConfigType } from "entities/config";
import { EncounterDetails } from "entities/issues";
import { useMemo } from "react";
import SingleEncounter from "./SingleEncounter";
import { EncounterOverlayHeightType } from "./type";

type EncounterYearProp = {
  encounter: EncounterDetails[];
  encounterHeight: number;
  handleToggleSoapNotes: (encounter: EncounterDetails) => void;
  tests?: ConfigType["tests"];
  vitals?: ConfigType["vitals"];
  encounterSectionHeight: EncounterOverlayHeightType;
  handleShowProcedueOrders: (encounterId: string) => void;
};

export const EncounterYear = ({
  encounter,
  encounterHeight,
  handleToggleSoapNotes,
  tests,
  vitals,
  encounterSectionHeight,
  handleShowProcedueOrders
}: EncounterYearProp) => {
  const encounterToShow = useMemo(() => {
    const data = encounter.slice(0, 3);

    return data;
  }, [encounter]);

  return (
    <div className="flex h-full w-full">
      {encounterToShow.map((item, i) => (
        <SingleEncounter
          encounter={item}
          key={item.id + item.pid + i}
          encounterHeight={encounterHeight}
          handleToggleSoapNotes={handleToggleSoapNotes}
          vitals={vitals}
          tests={tests}
          encounterSectionHeight={encounterSectionHeight}
          handleShowProcedueOrders={handleShowProcedueOrders}
        />
      ))}
    </div>
  );
};
