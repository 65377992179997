import axios, { AxiosError, AxiosInstance } from "axios";
import { APIError } from "entities/generic";
import { apiErrorHandler } from "utils/api-error-handler";
import { BASE_URL } from "utils/config";

export interface HttpGetRequestError {
  data: null;
  error: string | APIError<unknown>;
  code: number;
}
export interface HttpGetRequestSuccess<T> {
  data: T;
  error: null;
  code: boolean;
}

class HTTPClient {
  axiosInstance: AxiosInstance;

  constructor(private baseURL: string) {
    const axiosClient = axios.create({ baseURL: this.baseURL });

    this.axiosInstance = axiosClient;
  }

  // async get<T>(path: string, tags?: string[]) {
  //   try {
  //     const res = await fetch(`${BASE_URL}/api${path}`);
  //     if (!res.ok) {
  //       return {
  //         data: null,
  //         error: "An error occurred",
  //         code: res.status,
  //       } as HttpGetRequestError;
  //     }
  //     const json = await res.json();

  //     return {
  //       code: res.ok,
  //       data: json as T,
  //       error: null,
  //     } as HttpGetRequestSuccess<T>;
  //   } catch (error) {
  //     return apiErrorHandler(error as AxiosError);
  //   }
  // }

  async axiosGet(path: string, params?: object) {
    const res = await this.axiosInstance.get(path, {
      params,
    });
    return res.data;
  }
  async post<T>(path: string, data?: object) {
    try {
      const response = await this.axiosInstance.post(path, data);
      return {
        code: response.status,
        data: response.data as T,
        error: null,
      };
    } catch (error) {
      return apiErrorHandler(error as AxiosError);
    }
  }

  async delete<T>(path: string, data?: object) {
    try {
      const response = await this.axiosInstance.delete(path, {
        data,
      });
      return {
        code: response.status,
        data: response.data as T,
        error: null,
      };
    } catch (error) {
      return apiErrorHandler(error as AxiosError);
    }
  }

  async update<T>(path: string, data = {}, headers = {}) {
    try {
      const response = await this.axiosInstance.put(path, data, headers);
      return {
        error: null,
        code: response.status,
        data: response.data as T,
      };
    } catch (error) {
      return apiErrorHandler(error as AxiosError);
    }
  }
}

export const apiHTTPClient = new HTTPClient(`${BASE_URL}/api`);
